import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout/Layout";
import BtnMain from "../../components/buttons/btn_main";
import MainModal from "../../components/model/Model";
import { Link, useHistory } from "react-router-dom";
import ServiceAgreement from "../../components/model/ServiceAgreement";
import Signature from "../../components/model/Signature";
import { toggleModal } from "../../store/Actions/Ui";
import clsx from "clsx";
import BookCall from "../../components/model/BookCall";
import {
  setUserInfo,
  stripeConnect,
  stripeConnectSend,
} from "../../store/Actions";
import Loader from "../../components/Layout/loader/Loader";
import { set_cookie } from "../../utils";
const queryString = require("query-string");
function LastStep(props) {
  let history = useHistory();
  const url = props.location.search;
  let params = queryString.parse(url);
  const userInfo = useSelector((state) => state?.user?.userInfo?.data?.user);
  const model = useSelector((state) => state?.ui?.modals);
  const stepInfo = useSelector((state) => state?.user?.completedStep);
  const handleServiceClose = () =>
    dispatch(toggleModal(false, "service_agreement"));
  const handleSignatureClose = () => dispatch(toggleModal(false, "signature"));
  const [isLoading, SetLoading] = useState(false);
  const getCurrentStep = (prev, current) => {
    if (prev && !current) {
      return true;
    } else {
      return false;
    }
  };
  const dispatch = useDispatch();
  /*** 
    @purpose :hooks used for api call
    @Parameter : {} 
    @Author : INIC
    **/

  useEffect(() => {
    if (params?.code) {
      SetLoading(true);
      const body = { code: params.code, state: params.state };
      dispatch(stripeConnectSend(body)).then((res) => {
        if (res.success) {
          set_cookie("accessToken", res.jwtToken, 1);
          dispatch(setUserInfo(res));
          SetLoading(false);
          history.push("/dashboard");
        }
        SetLoading(false);
      });
    }
  }, []);

  var firstName = "";
  if (userInfo) {
    firstName = userInfo.firstName;
  }

  const handleStripe = () => {
    SetLoading(true);
    const body = { email: userInfo.email };
    dispatch(stripeConnect(body)).then((res) => {
      if (res.success) {
        window.open(res.data, "_blank");
        SetLoading(false);
      }
      SetLoading(false);
    });
  };
  /******************* 
@purpose : Render Last Step UI For Onboarding 
@Author  : INIC
******************/
  return (
    <Layout>
      <div className="step-main-wrap last-main-step">
        {isLoading && <Loader text="Loading" />}
        <div className="createAcount-wrap">
          <div className="step-wrap last-step-wrap">
            <div className="step-heading">
              We are excited to have you {firstName}! &#128588;
            </div>
            <div className="step-heading">Let’s get you started.</div>
            <div className="stepform-heading">
              Before we start connecting you with clients, there are a few
              things we need.
            </div>
            <div className="statusMain-block">
              <div className="statusMain-tile">
                <div className="statusMain-count">1</div>
                <div className="statusMain-text-wrap">
                  <div className="statusMain-text">
                    <div className="sm-text statusMain-heading">
                      PROFESSIONAL DETAILS
                    </div>
                    <div className="sm-text statusMain-subheading">
                      Detail about your profession and capabilities.
                    </div>
                  </div>
                  <div className="statusMain-status">
                    <span className="icon-check"></span>
                  </div>
                </div>
              </div>
              <div
                className={clsx("statusMain-tile", {
                  "pending-verifyStep-tile": !getCurrentStep(
                    stepInfo.professionalDetails,
                    stepInfo.serviceAgreement
                  ),
                  "current-verifyStep-tile": getCurrentStep(
                    stepInfo.professionalDetails,
                    stepInfo.serviceAgreement
                  ),
                })}
              >
                <div className="statusMain-count">2</div>
                <div className="statusMain-text-wrap">
                  <div className="statusMain-text">
                    <div className="sm-text statusMain-heading">
                      ACCEPT THE SERVICE AGREEMENT
                    </div>
                    <div className="sm-text statusMain-subheading">
                      Read and accept the Rightangled service agreement.
                    </div>
                  </div>
                  <div className="statusMain-status">
                    {!stepInfo.serviceAgreement ? (
                      <BtnMain
                        text="View"
                        onClick={() =>
                          dispatch(toggleModal(true, "service_agreement"))
                        }
                        className={"btn gray-line-btn"}
                        type="button"
                      />
                    ) : (
                      <span className="icon-check"></span>
                    )}
                  </div>
                </div>
                {getCurrentStep(
                  stepInfo.professionalDetails,
                  stepInfo.serviceAgreement
                ) ? (
                  <div className="current-indicator">&#128072;</div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={clsx("statusMain-tile", {
                  "pending-verifyStep-tile": !getCurrentStep(
                    stepInfo.serviceAgreement,
                    stepInfo.sign
                  ),
                  "current-verifyStep-tile": getCurrentStep(
                    stepInfo.serviceAgreement,
                    stepInfo.sign
                  ),
                })}
              >
                <div className="statusMain-count">3</div>
                <div className="statusMain-text-wrap">
                  <div className="statusMain-text">
                    <div className="sm-text statusMain-heading">
                      SIGN TO CONFIRM YOUR IDENTITY
                    </div>
                    <div className="sm-text statusMain-subheading">
                      We are serious about client privacy. Sign to confirm your
                      itentity.
                    </div>
                  </div>
                  <div className="statusMain-status">
                    {!stepInfo.sign ? (
                      <BtnMain
                        text="Sign"
                        onClick={() => dispatch(toggleModal(true, "signature"))}
                        className={"btn gray-line-btn disabled-btn"}
                        type="button"
                        disabled={
                          !getCurrentStep(
                            stepInfo.serviceAgreement,
                            stepInfo.sign
                          )
                        }
                      />
                    ) : (
                      <span className="icon-check"></span>
                    )}
                  </div>
                </div>
                {getCurrentStep(stepInfo.serviceAgreement, stepInfo.sign) ? (
                  <div className="current-indicator">&#128072;</div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={clsx("statusMain-tile", {
                  "pending-verifyStep-tile": !getCurrentStep(
                    stepInfo.sign,
                    stepInfo.bookCall
                  ),
                  "current-verifyStep-tile": getCurrentStep(
                    stepInfo.sign,
                    stepInfo.bookCall
                  ),
                })}
              >
                <div className="statusMain-count">4</div>
                <div className="statusMain-text-wrap">
                  <div className="statusMain-text">
                    <div className="sm-text statusMain-heading">
                      BOOK A CALL WITH MARCO
                    </div>
                    <div className="sm-text statusMain-subheading">
                      Marco welcomes all of our professional partners with a
                      quick call. He’s great. 👍
                    </div>
                  </div>
                  <div className="statusMain-status">
                    {!stepInfo.bookCall ? (
                      <BtnMain
                        text="Book"
                        onClick={() => dispatch(toggleModal(true, "bookcall"))}
                        className={"btn gray-line-btn disabled-btn"}
                        type="button"
                        disabled={
                          !getCurrentStep(stepInfo.sign, stepInfo.bookCall)
                        }
                      />
                    ) : (
                      <span className="icon-check"></span>
                    )}
                  </div>
                </div>
                {getCurrentStep(stepInfo.sign, stepInfo.bookCall) ? (
                  <div className="current-indicator">&#128072;</div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={clsx("statusMain-tile", {
                  "pending-verifyStep-tile": !getCurrentStep(
                    stepInfo.bookCall,
                    stepInfo.stripe
                  ),
                  "current-verifyStep-tile": getCurrentStep(
                    stepInfo.bookCall,
                    stepInfo.stripe
                  ),
                })}
              >
                <div className="statusMain-count">5</div>
                <div className="statusMain-text-wrap">
                  <div className="statusMain-text">
                    <div className="sm-text statusMain-heading">
                      CONNECT A STRIPE ACCOUNT
                    </div>
                    <div className="sm-text statusMain-subheading">
                      We pay our professionals with Stripe. Create or link your
                      account.
                    </div>
                  </div>
                  <div className="statusMain-status">
                    {!stepInfo.stripe ? (
                      <Link
                        onClick={handleStripe}
                        to="#"
                        className={clsx("btn btn-purple", {
                          "disabled-btn": !getCurrentStep(
                            stepInfo.bookCall,
                            stepInfo.stripe
                          ),
                        })}
                      >
                        Connect with Stripe
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {getCurrentStep(stepInfo.bookCall, stepInfo.stripe) ? (
                  <div className="current-indicator">&#128072;</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="text-end">
              <Link
                to="/"
                className="connect-later-link sm-text underline-anchor"
              >
                Connect a Stripe account later
              </Link>
            </div>
          </div>
        </div>
      </div>
      <MainModal
        body={<ServiceAgreement />}
        ModalTitle="Service Agreement"
        ModalDescription=""
        show={model?.service_agreement}
        onHide={handleServiceClose}
        keyModal="service_agreement"
      />
      <MainModal
        body={<Signature />}
        ModalTitle="Signature Agreement"
        ModalDescription=""
        show={model?.signature}
        onHide={handleSignatureClose}
        keyModal="signature"
      />
      <MainModal
        body={<BookCall />}
        ModalTitle="Book A Call"
        ModalDescription=""
        show={model?.bookcall}
        onHide={handleSignatureClose}
        keyModal="bookcall"
      />
    </Layout>
  );
}
export default LastStep;
