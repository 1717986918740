import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../store/Actions";
import { Form, FormGroup, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { changePasswordSchema } from "../../utils/schema";
import BtnMain from "../../components/buttons/btn_main";
import TextField from "../../components/inputs/textfield";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import Loader from "../../components/Layout/loader/Loader";
function PasswordReset(_props) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.ui?.loaders?.change_password);
  const [oldpasswordShown, setoldPasswordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [error, setError] = useState(false);
  const [successMsg, setSuccess] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };
  const toggleoldpasswordShownVisiblity = () => {
    setoldPasswordShown(oldpasswordShown ? false : true);
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    onSubmit(values, { resetForm }) {
      const body = values;
      dispatch(changePassword(body, "change_password")).then((res) => {
        if (res.success) {
          setSuccess(true);
          setError("");
          resetForm({ values: "" });
        } else {
          setError(res.errorMessage);
          setSuccess(false);
        }
      });
    },
    validationSchema: changePasswordSchema,
  });

  /******************* 
@purpose : used for Form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };
  /******************* 
@purpose : Render Change Password Page UI 
@Author  : INIC
******************/
  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <div className="dashboard-inner-content">
        <div className="profile-main-wrap">
          <Form onSubmit={handleSubmit} noValidate>
            {error && <div className="message-block">Something Went Wrong</div>}
            <div className="big-heading h3">Change password</div>
            <Row>
              <Col xs={12} md={5}>
                <FormGroup className="form-group input-ic-wrap">
                  <TextField
                    type={oldpasswordShown ? "text" : "password"}
                    placeholder="Old Password"
                    name="oldPassword"
                    formik={formik}
                    id="OldPassword"
                    showUntoucherError
                  />
                  <i
                    className={`${
                      oldpasswordShown ? "icon-eye-slash" : "icon-eye"
                    } toggle-password`}
                    aria-hidden="true"
                    onClick={toggleoldpasswordShownVisiblity}
                  ></i>
                </FormGroup>
                <FormGroup className="form-group input-ic-wrap">
                  <TextField
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    name="newPassword"
                    formik={formik}
                    id="password"
                    showUntoucherError
                  />
                  <i
                    className={`${
                      passwordShown ? "icon-eye-slash" : "icon-eye"
                    } toggle-password`}
                    aria-hidden="true"
                    onClick={togglePasswordVisiblity}
                  ></i>
                </FormGroup>
                <FormGroup className="form-group input-ic-wrap">
                  <TextField
                    type={confirmPasswordShown ? "text" : "password"}
                    placeholder="Confirm password"
                    name="confirmNewPassword"
                    formik={formik}
                    id="cpassword"
                    showUntoucherError
                  />
                  <i
                    className={`${
                      confirmPasswordShown ? "icon-eye-slash" : "icon-eye"
                    } toggle-password`}
                    aria-hidden="true"
                    onClick={toggleConfirmPasswordVisiblity}
                  ></i>
                </FormGroup>
                <BtnMain
                  text="Save Changes"
                  className="btn btn-primary password-reset-btn "
                  type="submit"
                >
                  {" "}
                </BtnMain>
              </Col>
            </Row>
          </Form>
          {error && (
            <div className="login-block ">
              <div className="user-block user-block-sm pe-0">
                <div className="userImg-block ">
                  <div className="userIc danger-bg">
                    <span className="icon-times-circle white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text user-textHeading">
                    {error}
                  </div>
                </div>
              </div>
            </div>
          )}
          {successMsg && (
            <div className="login-block mb-3 pb-3">
              <div className="user-block user-block-sm pe-0 align-items-start">
                <div className="userImg-block ">
                  <div className="userIc success-bg">
                    <span className="icon-check-circle white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text user-textHeading">
                    You have successfully changed your password on Rightangle.
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="dashboard-footer"></div>
    </Layout>
  );
}
export default PasswordReset;
