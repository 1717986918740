import React, { Fragment, useEffect, useRef, useState } from "react";
import { FormGroup, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { profileSchema } from "../../utils/schema";
import BtnMain from "../../components/buttons/btn_main";
import TextField from "../../components/inputs/textfield";
import UploadFile from "../../components/inputs/UploadFile";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getProfessionalList, sendProfileData } from "../../store/Actions";
import SignatureCanvas from "react-signature-canvas";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import { dataURLtoFile } from "../../utils";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GOOGLE_KEY } from "../../config";
import Loader from "../../components/Layout/loader/Loader";
function ClientProfile(_props) {
  const [previewBanner, SetpreviewBanner] = useState("");
  const [width, setWidth] = useState(null);
  const [error, setError] = useState("");
  const isLoading = useSelector((state) => state?.ui?.loaders?.profile);
  const [value, setValue] = useState(null);
  const [signPlacehoder, setsignPlacehoder] = useState();
  const ref = useRef(null);
  const [reviewTest, setReviewTest] = useState("");
  let sigCanvas;
  const dispatch = useDispatch();
  const professionalInfo = useSelector(
    (state) => state?.user?.professionListInfo
  );
  var length = Object.keys(professionalInfo).length;
  const signClearHandle = () => {
    sigCanvas.clear();
    setsignPlacehoder(true);
  };
  const isEmptyHandle = () => {
    setsignPlacehoder(sigCanvas.isEmpty());
  };
  useEffect(() => {
    const body = "";
    isEmptyHandle();
    setWidth(ref.current?.clientWidth);
    dispatch(getProfessionalList(body));
  }, []);
  const formik = useFormik({
    initialValues: {
      Title: "",
      FirstName: "",
      LastName: "",
      PhoneNumber: "",
      Email: "",
      Skype: "",
      Profession: "",
      Consultation: false,
      ConsultationPrice: "",
      ConsultationPrice2: "",
      Facility: false,
      gmcReferenceNumber: "",
      Address1: "",
      Address2: "",
      Country: "",
      State: "",
      City: "",
      Postcode: "",
      Signature: "",
    },
    onSubmit(values) {
      const formData = new FormData();
      formData.append("isHaveFacility", false);
      var heartDNA = false;
      var fitnessDNA = false;
      var covid = false;
      var pomStatus = false;
      if (reviewTest == 70 || reviewTest == 80) {
        pomStatus = true;
      } else if (reviewTest == 20 || reviewTest == 30) {
        fitnessDNA = true;
      } else if (reviewTest == 10 || reviewTest == 40) {
        heartDNA = true;
      } else if (reviewTest == 50) {
        fitnessDNA = true;
        heartDNA = true;
      } else if (reviewTest == 60) {
        covid = true;
      } else {
        heartDNA = false;
        fitnessDNA = false;
        covid = false;
        pomStatus = false;
      }
      formData.append("includeHeartDNATest", heartDNA);
      formData.append("includeFitnessDNATest", fitnessDNA);
      formData.append("includeCovid19Test", covid);
      formData.append("includePOM", pomStatus);
      formData.append("Title", values.Title);
      formData.append("FirstName", values.FirstName);
      formData.append("LastName", values.LastName);
      formData.append("PhoneNumber", values.PhoneNumber);
      formData.append("Email", values.Email);
      formData.append("Skype", values.Skype);
      formData.append("ProfessionId", values.Profession);
      formData.append("Consultation", values.Consultation);
      formData.append("ConsultationPrice", values.ConsultationPrice);
      formData.append("ConsultationPrice2", values.ConsultationPrice2);
      formData.append("Facility", values.Facility);
      formData.append("GMCReferenceNumber", values.gmcReferenceNumber);
      formData.append("Address1", values.Address1);
      formData.append("Address2", values.Address2);
      formData.append("City", values.City);
      formData.append("State", values.State);
      formData.append("Country", values.Country);
      formData.append("Postcode", values.Postcode);
      formData.append("profile", values.image);
      formData.append("signature", values.Signature);
      dispatch(sendProfileData(formData, "profile")).then((res) => {
        if (!res.success) {
          setError(res.errorMessage);
        } else setError(res.errorMessage);
      });
    },
    validationSchema: profileSchema,
  });
  const titleOptions = [
    { value: "", label: "Select Title" },
    { value: "Prof", label: "Prof" },
    { value: "Dr", label: "Dr." },
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
    { value: "Miss", label: "Miss" },
  ];

  /******************* 
@purpose : used for Profesional List Array
@Author : INIC
******************/
  const getrows = [];

  if (length > 0) {
    var i = 1;
    Object.keys(professionalInfo).forEach(function (key) {
      if (i < length) {
        getrows.push({
          value: professionalInfo[key].partnerProfessionId,
          label: professionalInfo[key].partnerProfessionName,
        });
      }
      i++;
    });
  }
  const Profession = getrows;
  const onDrop = (files) => {
    if (files) {
      SetpreviewBanner(global.URL.createObjectURL(files[0]));
      formik?.setFieldValue("image", files[0]);
    }
  };
  /******************* 
@purpose : used for form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    var file = sigCanvas.getTrimmedCanvas().toDataURL("image/png");
    var sign = dataURLtoFile(file, "signature.png");
    formik?.setFieldValue("Signature", sign);
    formik.submitForm();
  };
  /******************* 
@purpose : used for Google Places Handle
@Parameter : {value} 
@Author : INIC
******************/
  const handleAutocomplete = (value) => {
    setValue();

    formik.setFieldValue("Address1", value["value"]["description"]);
    var countryVal = value["value"]["terms"].slice(-1)[0];
    var stateVal = value["value"]["terms"].slice(-2)[0];
    var cityVal = value["value"]["terms"].slice(-3)[0];
    formik.setFieldValue("City", cityVal["value"]);
    formik.setFieldValue("State", stateVal["value"]);
    formik.setFieldValue("Country", countryVal["value"]);
  };
  /******************* 
@purpose : Render Profile Page UI 
@Author  : INIC
******************/

  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <Form onSubmit={handleSubmit} className="dashboard-form">
        {error && <div className="message-block">{error}</div>}
        <div className="dashboard-inner-content">
          <div className="profile-main-wrap">
            <div className="big-heading h3">Profile</div>
            <div className="profile-wrap">
              <div className="label-heading xs-text b-700 mb-3">AVATAR</div>
              <UploadFile onDrop={onDrop} accept="image/*">
                {previewBanner ? (
                  <div className="preview-wrap">
                    <div className="preview-img">
                      <div className="preview-action">
                        <span
                          className="preview-ic icon-edit"
                          onClick={(e) => {
                            SetpreviewBanner("");
                            e.preventDefault();
                          }}
                        ></span>
                        <div className="b-700 sm-text">Edit Avatar</div>
                      </div>
                      <div
                        className="background-img"
                        style={{
                          backgroundImage: "url(" + previewBanner + ")",
                        }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <div className="profile-upload">
                      <span className="icon-image-upload"></span>
                      <div className="profileUpload-heading sm-text b-700">
                        Upload Avatar
                      </div>
                      <div className="profileUpload-subheading xs-text">
                        Photo must be below 5MB.
                      </div>
                    </div>
                  </Fragment>
                )}
              </UploadFile>
              {formik.errors.image ? (
                <div className="message-block">{formik.errors.image}</div>
              ) : null}
            </div>

            <Row>
              <Col xs={12}>
                <div className="label-heading xs-text b-700 mb-3">
                  PERSONAL INFORMATION
                </div>
              </Col>
              <Col xs={12} md={8}>
                <div className="input-withSelect">
                  <FormGroup className="form-group">
                    <Select
                      classNamePrefix="custom-select"
                      placeholder="Select Title"
                      type="text"
                      id="title"
                      name="Title"
                      onChange={(e) => {
                        formik?.setFieldValue("Title", e.value);
                      }}
                      options={titleOptions}
                    />
                    {formik.errors.Title ? (
                      <div className="message-block">{formik.errors.Title}</div>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="form-group">
                    <TextField
                      type="text"
                      placeholder="First name"
                      name="FirstName"
                      formik={formik}
                      id="firstName"
                      showUntoucherError
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Last name"
                    name="LastName"
                    formik={formik}
                    id="lastName"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Phone number"
                    name="PhoneNumber"
                    formik={formik}
                    id="Phonenumber"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Email"
                    name="Email"
                    formik={formik}
                    id="Email"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Skype"
                    name="Skype"
                    formik={formik}
                    id="Skype"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>

              <Col xs={12}>
                <div className="label-heading xs-text b-700 mb-3 pt-4">
                  PERSONAL INFORMATION
                </div>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group mb-3">
                  <Select
                    classNamePrefix="custom-select"
                    placeholder="Profession"
                    type="text"
                    id="Profession"
                    name="Profession"
                    onChange={(e) => {
                      setReviewTest(e.value);
                      formik?.setFieldValue("Profession", e.value);
                    }}
                    options={Profession}
                  />
                  {formik.errors.Profession ? (
                    <div className="message-block">
                      {formik.errors.Profession}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <div className="tag-block mb-4">
                  {reviewTest == 70 || reviewTest == 80 ? (
                    <div className="tag-tile">POM</div>
                  ) : reviewTest == 20 || reviewTest == 30 ? (
                    <div className="tag-tile">Fitness DNA Test</div>
                  ) : reviewTest == 10 || reviewTest == 40 ? (
                    <div className="tag-tile">Heart DNA Test</div>
                  ) : reviewTest == 50 ? (
                    <>
                      <div className="tag-tile">Heart DNA Test</div>
                      <div className="tag-tile">Fitness DNA Test</div>
                    </>
                  ) : reviewTest == 60 ? (
                    <div className="tag-tile">Covid-19 Test</div>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col xs={12} className="noConsultation-wrap mb-4">
                <FormGroup className="form-group mb-0">
                  <Form.Check
                    type={"checkbox"}
                    id={`noConsultation`}
                    name="Consultation"
                  >
                    <Form.Check.Input
                      type={"checkbox"}
                      isValid
                      onChange={(e) => {
                        formik?.setFieldValue("Consultation", e.target.checked);
                      }}
                    />
                    <Form.Check.Label>{`No Consultation`}</Form.Check.Label>
                  </Form.Check>
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="ConsultationPrice"
                    name="ConsultationPrice"
                    formik={formik}
                    id="ConsultationPrice"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Consultation Price"
                    name="ConsultationPrice2"
                    formik={formik}
                    id="ConsultationPrice"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12} className="">
                <FormGroup className="form-group">
                  <Form.Check
                    type={"checkbox"}
                    id={`Not have facility`}
                    name="Facility"
                  >
                    <Form.Check.Input
                      type={"checkbox"}
                      isValid
                      onChange={(e) => {
                        formik?.setFieldValue("Facility", e.target.checked);
                      }}
                    />
                    <Form.Check.Label>{`Not have facility`}</Form.Check.Label>
                  </Form.Check>
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="GMC Reference Number"
                    name="gmcReferenceNumber"
                    formik={formik}
                    id="gmcReferenceNumber"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12}></Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <GooglePlacesAutocomplete
                    apiKey={GOOGLE_KEY}
                    selectProps={{
                      value,
                      onChange: (value) => handleAutocomplete(value),
                    }}
                    name="Address1"
                    value={value}
                  />
                  {formik.errors.Address1 ? (
                    <div className="message-block">
                      {formik.errors.Address1}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Address 2"
                    name="Address2"
                    formik={formik}
                    id="Address2"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12}></Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Country"
                    name="Country"
                    formik={formik}
                    id="Country"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="State"
                    name="State"
                    formik={formik}
                    id="State"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12}></Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="City"
                    name="City"
                    formik={formik}
                    id="City"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Post code"
                    name="Postcode"
                    formik={formik}
                    id="Postcode"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <div className="label-heading xs-text b-700 mb-3 pt-4">
                  SIGNATURE
                </div>
              </Col>
              <Col xs={12} md={8}>
                <div className="client-profile-sign" ref={ref}>
                  {signPlacehoder ? (
                    <span className="do-sign-wrap lg-text">Do sign here</span>
                  ) : (
                    ""
                  )}
                  <SignatureCanvas
                    penColor="black"
                    maxWidth={2}
                    canvasProps={{
                      width: width ? width : 500,
                      height: 167,
                      className: "signature-canvas",
                    }}
                    ref={(ref) => {
                      sigCanvas = ref;
                    }}
                    onEnd={isEmptyHandle}
                    onBegin={isEmptyHandle}
                  />
                  <BtnMain
                    text="Reset"
                    className="btn gray-line-btn signatureReset-btn"
                    onClick={signClearHandle}
                    type="button"
                    iconLeft={<span className="icon-reset"></span>}
                  >
                    {" "}
                  </BtnMain>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="dashboard-footer">
          <BtnMain
            text="Save Changes"
            className="btn btn-primary saveChanges-btn"
            type="submit"
          >
            {" "}
          </BtnMain>
        </div>
      </Form>
    </Layout>
  );
}
export default ClientProfile;
