import React, { Fragment, useState, useRef, useEffect } from "react";
import { toggleModal } from "../../store/Actions/Ui";
import { useFormik } from "formik";
import BtnMain from "../buttons/btn_main";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import UploadFile from "../inputs/UploadFile";
import { dataURLtoFile } from "../../utils";
import { setLastCompletedStep, sendSignatureData } from "../../store/Actions";
import Loader from "../Layout/loader/Loader";
import { Form } from "react-bootstrap";

function Signature() {
  const [previewBanner, SetpreviewBanner] = useState("");
  const dispatch = useDispatch();
  const [width, setWidth] = useState(null);
  const [signHide, setSignHide] = useState(true);
  const [error, setError] = useState("");
  const ref = useRef(null);
  let sigCanvas;
  const isLoading = useSelector((state) => state?.ui?.loaders?.signature);
  const userInfo = useSelector((state) => state?.user?.userInfo?.data?.user);
  const signClearHandle = () => sigCanvas.clear();

  useEffect(() => {
    setWidth(ref.current?.clientWidth - 64);
  });

  const formik = useFormik({
    initialValues: {
      signature: "",
      image: "",
    },
    onSubmit(values) {
      const formData = new FormData();
      formData.append("file", values.signature);
      formData.append("image", values.image);
      formData.append("UserId", userInfo.id);

      dispatch(sendSignatureData(formData, "signature")).then((res) => {
        if (res.data.success) {
          dispatch(setLastCompletedStep(true, "sign"));
          dispatch(toggleModal(false, "signature"));
        } else setError(res.data.errorMessage);
      });
    },
  });
  /******************* 
    @purpose : used for drop a video
    @Parameter : {file} 
    @Author : INIC
    ******************/
  const onDrop = (files) => {
    if (files) {
      setSignHide(false);
      SetpreviewBanner(global.URL.createObjectURL(files[0]));
      formik?.setFieldValue("image", files[0]);
    }
  };
  /******************* 
@purpose : used for Form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    if (sigCanvas) {
      var imgobj = dataURLtoFile(
        sigCanvas.getTrimmedCanvas().toDataURL("image/png"),
        "signature.png"
      );
      formik?.setFieldValue("signature", imgobj);
    }
    formik.submitForm();
  };

  /******************* 
    @purpose : Signature Modal Component
    @Parameter : {} 
    @Author : INIC
    ******  ************/

  return (
    <>
      {isLoading && <Loader text="Loading" />}
      <Form onSubmit={handleSubmit} noValidate>
        {error && <div className="message-block">{error}</div>}
        <div className="modal-body-inner signature-wrap">
          {signHide ? (
            <div className="sm-text signature-text">
              Sign to confirm your identity.
            </div>
          ) : (
            ""
          )}

          <div className="sm-text signature-text">
            <UploadFile onDrop={onDrop} accept="image/*">
              <div className="dropzon-main-wrap">
                {previewBanner ? (
                  <div className="preview-wrap">
                    <div className="preview-img">
                      <span
                        className="preview-ic icon-multiply"
                        onClick={(e) => {
                          setSignHide(true);
                          SetpreviewBanner("");
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      ></span>
                      <img src={previewBanner} alt="icon" />
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    You can use your mouse or Upload an Image of your signature
                  </Fragment>
                )}
              </div>
            </UploadFile>
          </div>
          {signHide ? (
            <div className="signature-canvas signture-box" ref={ref}>
              <SignatureCanvas
                penColor="black"
                maxWidth={2}
                canvasProps={{
                  width: width ? width : 500,
                  height: 268,
                  className: "signature-canvas",
                }}
                ref={(ref) => {
                  sigCanvas = ref;
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="modal-footer justify-content-center">
          {signHide ? (
            <BtnMain
              text="Reset"
              className="btn  gray-line-btn m-0 me-2"
              onClick={signClearHandle}
              type="button"
            />
          ) : (
            ""
          )}
          <BtnMain
            text="Done"
            className="btn btn-primary m-0 ms-2"
            type="submit"
          />
        </div>
      </Form>
    </>
  );
}
export default Signature;
