import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import { sendProfessionalNo, getCountryCodeList } from "../../store/Actions";
import Select from "react-select";
import { step3Schema } from "../../utils/schema";
import Loader from "../Layout/loader/Loader";
import BtnMain from "../buttons/btn_main";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, FormGroup } from "react-bootstrap";
import TextField from "../inputs/textfield";

export default function Step3(props) {
  const [error, setError] = useState("");
  const isLoading = useSelector((state) => state?.ui?.loaders?.step3);
  const userInfoUpdated = useSelector(
    (state) => state?.user?.userInfoUpdated?.data?.data
  );
  const countryInfo = useSelector((state) => state?.user?.CountryCodeListInfo);
  var length = Object.keys(countryInfo).length;

  const dispatch = useDispatch();

  /*** 
    @purpose :hooks used for api call
    @Parameter : {} 
    @Author : INIC
    **/
  useEffect(() => {
    const body = "";
    dispatch(getCountryCodeList(body));
  }, []);

  const formik = useFormik({
    initialValues: {
      CountryCode: "",
      PhoneNumber: "",
    },
    onSubmit(values) {
      const body = values;
      body.id = userInfoUpdated.id;
      body.userId = userInfoUpdated.userId;
      dispatch(sendProfessionalNo(body, "step3")).then((res) => {
        if (res.success) {
          props.goToStep(4);
          localStorage.setItem("CurrentStep", 4);
        } else setError(res.errorMessage);
      });
    },
    validationSchema: step3Schema,
  });
  /******************* 
@purpose : used for Form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };
  /******************* 
@purpose : used for CountryCode List Array
@Author : INIC
******************/
  const getrows = [];

  if (length > 0) {
    var i = 1;
    Object.keys(countryInfo).forEach(function (key) {
      if (i < length) {
        getrows.push({
          value: countryInfo[key].name,
          label: countryInfo[key].id,
        });
      }
      i++;
    });
  }
  const countryCode = getrows;
  /******************* 
@purpose : Render Step 3 UI For Onboarding 
@Author  : INIC
******************/
  return (
    <Layout>
      <div className="step-main-wrap">
        <Form onSubmit={handleSubmit} noValidate>
          <div className="progress-baar">
            <div className="progress-baar-value" style={{ width: "50%" }}></div>
          </div>
          {isLoading && <Loader text="Loading" />}
          <div className="createAcount-wrap">
            <Link to="/" className="step-skip-btn lg-text">
              Skip this step, I am just taking a look
            </Link>
            <div className="step-wrap">
              <div className="step-heading">
                What is the best phone number to reach you on?
              </div>
              <div className="step-idea-box">
                <div className="step-idea-ic">
                  <i className="icon-lightbulb-alt"></i>
                </div>
                <div className="step-idea-text">
                  Our team will give you a call over the next couple of days to
                  ensure you are a qualified professional.
                </div>
              </div>
              {error && <div className="message-block">{error}</div>}
              <div className="number-group">
                <div className="step-selection">
                  <Select
                    classNamePrefix="custom-select"
                    placeholder="+44"
                    type="text"
                    id="countryCode"
                    name="CountryCode"
                    onChange={(e) => {
                      formik?.setFieldValue("CountryCode", e.label);
                    }}
                    options={countryCode}
                  />
                  {formik.errors.CountryCode ? (
                    <div className="message-block">
                      {formik.errors.CountryCode}
                    </div>
                  ) : null}
                  {/* </FormGroup> */}
                </div>
                <FormGroup className="form-group  input-ic-wrap">
                  <TextField
                    type="text"
                    placeholder="Eg. 20 7183 8750"
                    name="PhoneNumber"
                    formik={formik}
                    id="PhoneNumber"
                    showUntoucherError
                  />
                  <i className="icon-phone-alt" aria-hidden="true"></i>
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="step-footer">
            <div className="step-footer-cotainer">
              <BtnMain
                text="Back"
                className="btn gray-line-btn me-4"
                onClick={() => props.goToStep(2)}
                type="button"
              />
              <BtnMain text="Next" className="btn btn-primary" type="submit" />
            </div>
          </div>
        </Form>
      </div>
    </Layout>
  );
}
