import React from "react";
import { useDropzone } from "react-dropzone";

export default function UploadFile(props) {
  /*******************
   @purpose : DropZone Intial configuraiotn
   @Parameter : {}
   @Author : INIC
   ******************/
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: props.onDrop,
    multiple: props.multiple,
    disabled: props.isDisabled,
    maxSize: props.maxSize,
    accept: props.accept,
  });
  /******************* 
    @purpose : Render Upload File Component
    @Parameter : {} 
    @Author : INIC
    ******************/
  return (
    <div className={props.classes} {...getRootProps()}>
      <input
        name="image"
        data-testid="dropzone-upload"
        id="dropzone-upload"
        {...getInputProps()}
        {...props.inputProps}
      />
      {props.children}
    </div>
  );
}
