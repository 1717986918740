import React from "react";

import { Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfilePhoto from "./../../../assets/images/temp-img/profile_photo.png";
import medicine from "./../../../assets/images/temp-img/medicine.png";
import questionMark from "./../../../assets/images/color-icon/question-mark.svg";
import tShirt from "./../../../assets/images/color-icon/t-shirt.svg";
import Layout from "../../../components/Layout/dashboard-layout/Layout";
import BtnMain from "../../../components/buttons/btn_main";
function UserProfile(_props) {
  /******************* 
@Purpose : Used for render UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <Layout>
      <div className="dashboard-inner-content ">
        <Breadcrumb>
          <Breadcrumb.Item href="/assigned-tasks" className="sm-text ">
            Assigned Tasks <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            href="/prescriber/current-report"
            className="sm-text "
          >
            Sarah Jack <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>

          <Breadcrumb.Item active className="sm-text">
            Lifestyle and Behaviour Quiz
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="current-report-wrap">
          <div className="currentReportProfile-block">
            <div className="user-block user-block-lg">
              <div className="userImg-block">
                <div
                  className="user-img"
                  style={{ backgroundImage: "url(" + ProfilePhoto + ")" }}
                ></div>
              </div>
              <div className="user-text">
                <div className="h3 mb-2 black-text b-700">Samantha Jackson</div>

                <div className="sm-text mb-4 black-text number-space">
                  +1 3453 544 434
                </div>

                <div className="tag-block blue-tag-block">
                  <div className="tag-tile">Female</div>
                  <div className="tag-tile">24 Years Old</div>
                  <div className="tag-tile">69 kg</div>
                  <div className="tag-tile">White</div>
                  <div className="tag-tile">United Kingdom</div>
                  <div className="tag-tile">Drinker</div>
                  <div className="tag-tile">Smoker</div>
                  <div className="tag-tile">Self-employed</div>
                  <div className="tag-tile">Alergies</div>
                  <div className="tag-tile">Asthma</div>
                </div>
              </div>
            </div>
          </div>
          <div className="black-text sm-text b-700 ">Current medications</div>
          <div className="grey-text sm-text mb-3 ">
            Laborum ullam qui quo et. Nobis saepe error quo sunt accusantium
            sunt omnis at. Unde voluptatem quasi fugiat.
          </div>
          <Row className="">
            <Col className="col-auto">
              <div className="black-text sm-text b-700 mb-2">Risks</div>
              <div className="tag-block red-tag-block">
                <div className="tag-tile">Repeat purchase</div>
              </div>
            </Col>
            <Col className="col-auto">
              <div className="black-text sm-text b-700 mb-2">
                Repeat purchase
              </div>
              <div className="tag-block red-tag-block">
                <div className="tag-tile">High HDL Cholesterol</div>
              </div>
            </Col>
          </Row>
          <div className="profileInfo-wrap mt-5">
            <div className="grey-text xs-text b-700 profileInfo-heading mb-3">
              General Quiz{" "}
            </div>
            <div className="profileInfo-block black-text pb-2 mb-1">
              <div className="profileInfo-tile">
                <div className="user-block">
                  <div className="userImg-block ">
                    <div className="userIc light-bg">
                      <img src={questionMark} alt={questionMark} />
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Completed
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      June 23, 2021 at 21:34
                    </div>
                  </div>
                </div>
                <div className="profileInfo-action d-flex">
                  <Link to={"/"} className="btn gray-line-btn ">
                    View{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="profileInfo-wrap mt-0">
            <div className="grey-text xs-text b-700 profileInfo-heading mb-3">
              Fitness DNA Questions{" "}
            </div>
            <div className="profileInfo-block black-text pb-2 mb-1">
              <div className="profileInfo-tile">
                <div className="user-block">
                  <div className="userImg-block ">
                    <div className="userIc light-bg">
                      <img src={tShirt} alt={tShirt} />
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Completed
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      June 23, 2021 at 21:34
                    </div>
                  </div>
                </div>
                <div className="profileInfo-action d-flex">
                  <Link to={"/"} className="btn gray-line-btn ">
                    Start{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="medicine-wrap">
            <div className="grey-text xs-text b-700 medicine-heading mb-3">
              Previous purchases{" "}
            </div>
            <div className="medicine-block">
              <Row>
                <Col md={6}>
                  <div className="medicine-tile">
                    <div className="user-block user-block-md">
                      <div className="userImg-block ">
                        <div
                          className="user-img rounded-border"
                          style={{ backgroundImage: "url(" + medicine + ")" }}
                        ></div>
                      </div>
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading mb-2">
                          Azithromycin 250 mg
                        </div>
                        <div className="sm-text grey-text user-textSubHeading mb-2">
                          Our COVID-19 Antibody test will tell you...
                        </div>
                        <div className="d-md-flex justify-content-between">
                          <div className="sm-text black-text">Dec 01, 2021</div>
                          <div className="sm-text black-text">Quantity: 2</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="medicine-tile">
                    <div className="user-block user-block-md">
                      <div className="userImg-block ">
                        <div
                          className="user-img rounded-border"
                          style={{ backgroundImage: "url(" + medicine + ")" }}
                        ></div>
                      </div>
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading mb-2">
                          Azithromycin 250 mg
                        </div>
                        <div className="sm-text grey-text user-textSubHeading mb-2">
                          Our COVID-19 Antibody test will tell you...
                        </div>
                        <div className="d-md-flex justify-content-between">
                          <div className="sm-text black-text">Dec 01, 2021</div>
                          <div className="sm-text black-text">Quantity: 2</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="medicine-tile">
                    <div className="user-block user-block-md">
                      <div className="userImg-block ">
                        <div
                          className="user-img rounded-border"
                          style={{ backgroundImage: "url(" + medicine + ")" }}
                        ></div>
                      </div>
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading mb-2">
                          Azithromycin 250 mg
                        </div>
                        <div className="sm-text grey-text user-textSubHeading mb-2">
                          Our COVID-19 Antibody test will tell you...
                        </div>
                        <div className="d-md-flex justify-content-between">
                          <div className="sm-text black-text">Dec 01, 2021</div>
                          <div className="sm-text black-text">Quantity: 2</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="dashboard-footer">
          <BtnMain
            text="Call"
            className="btn gray-line-btn me-4 fixWidth-btn"
            type="button"
          />
          <BtnMain
            text="Message"
            className="btn gray-line-btn  fixWidth-btn"
            type="button"
          />
        </div>
      </div>
    </Layout>
  );
}
export default UserProfile;
