import React from "react";
import { useDispatch } from "react-redux";
import BtnMain from "../../../../components/buttons/btn_main";
import { toggleModal } from "../../../../store/Actions/Ui";
function GeneralQuestion(_props) {
  const dispatch = useDispatch();

  /******************* 
@Purpose : Used for render UI
@Parameter : {}
@Author : INIC
******************/

  return (
    <div className="tab-inner">
      <div className="btn-heading-wrap">
        <h3 className="h3 mb-4 black-text">1.Questions</h3>
        <BtnMain
          text="Add Note"
          className="btn btn-primary"
          onClick={() => dispatch(toggleModal(true, "add_note"))}
          type="submit"
        />
      </div>
      <div className="steps-block">
        <div className="steps-tile">
          <div className="steps-digit">1</div>
          <div className="steps-text">Questions</div>
        </div>
      </div>
      <div className="tag-block lightGrey-tag-block normalText-tag-block mb-4">
        <div className="tag-tile b-400">Completed: June 23, 2021 at 21:34</div>
      </div>
      <div className="dashboard-footer">
        <BtnMain
          text="Back"
          className="btn gray-line-btn me-4 fixWidth-btn"
          type="button"
        />
        <BtnMain
          text="Next"
          className="btn btn-primary fixWidth-btn"
          type="button"
        />
      </div>
    </div>
  );
}
export default GeneralQuestion;
