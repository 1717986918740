import { post,get,formdata} from '../../api'; // API Types
import * as types from './Types'; // Redux actions payload types
import { set_cookie } from './../../utils'; // Utility functions
import Routes from '../../api/Routes'; // API endpoint routes
import { alertDialogue } from '../../utils';
import {setUserInfo,setUserInfoUpdated,getProfessionalInfo,getMotivationalInfo,getCountryCodeInfo,getServiceAgreement} from "./User"
import {setUiKey} from "./Ui";
const {
    LOGIN,
    REGISTER,
    RESETPASSWORD,
    CHANGEPASSWORD,
    GOOGLEREGISTER,
    ProfessionalList,
    ProfessionalData,
    CountryCodeList,
    ProfessionalNo,
    ProfessionalAddress,
    PrimaryMotivationlList,
    PrimaryMotivationlAdd,
    ServiceAgreement,
    ServiceAgreementData,
    SignatureData,
    FORGOT,
    ProfileData,
    StripeConnect,
    StripeConnectSend,
} = Routes;


/******************** 
@purpose : Used for login user
@Parameter : { data, dispatch }
@Author : INIC
******************/
export const login = (data,loader) => (dispatch) => {
    let payload = {
        email: data.email,
        password: data.password,
        User_Type: "Partner",
       
    };
    return post(LOGIN, payload,loader).then((res) => {
        if (res.success)
        {
            set_cookie('accessToken',res.jwtToken, 1 );
            dispatch(setUserInfo(res));
       }
        return res;
    });
};




/******************** 
@purpose : Used for register 
@Parameter : { data }
@Author : INIC
******************/
export const register = (data,loader ) => (dispatch) => {
   
    data.device_type = 'web';
       
    return post(REGISTER, data, loader).then((res) => {
       
        dispatch(setUserInfo(res));
        
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

export const googleLogin = (data,loader ) => (dispatch) => {
   
  
    return post(GOOGLEREGISTER, data, loader).then((res) => {
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Get Profession List in Onboarding Step 2 
@Parameter : { data }
@Author : INIC
******************/

export const getProfessionalList = (data,loader ) => (dispatch) => {
   
   
    return get(ProfessionalList, data, loader).then((res) => {
        dispatch(getProfessionalInfo(res));
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};


/******************** 
@purpose : Used for Sending Profession Data to api in Onboarding Step 2 
@Parameter : { data }
@Author : INIC
******************/
export const getProfessionalData = (data,loader ) => (dispatch) => {
   

    return formdata(ProfessionalData, data, loader).then((res) => {
        dispatch(setUserInfoUpdated(res));    
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};
/******************** 
@purpose : Used for Get Country Code List in Onboarding Step 3 
@Parameter : { data }
@Author : INIC
******************/

export const getCountryCodeList = (data,loader ) => (dispatch) => {
   
   
    return get(CountryCodeList, data, loader).then((res) => {
        dispatch(getCountryCodeInfo(res));
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};
/******************** 
@purpose : Used for Sending Profession Phone no to api in Onboarding Step 3 
@Parameter : { data }
@Author : INIC
******************/
export const sendProfessionalNo = (data,loader ) => (dispatch) => {
   
    
    return post(ProfessionalNo, data, loader).then((res) => {
               
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Sending Profession Address to api in Onboarding Step 4 
@Parameter : { data }
@Author : INIC
******************/
export const sendProfessionalAddress = (data,loader ) => (dispatch) => {
   
    
    return post(ProfessionalAddress, data, loader).then((res) => {
               
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Get Primary Motivation List in Onboarding Step 5 
@Parameter : { data }
@Author : INIC
******************/

export const getPrimaryMotivationList = (data,loader ) => (dispatch) => {
   
   
    return get(PrimaryMotivationlList, data, loader).then((res) => {
        dispatch(getMotivationalInfo(res));
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Get Primary Motivation List in Onboarding Step 5 
@Parameter : { data }
@Author : INIC
******************/

export const sendPrimaryMotivation = (data,loader ) => (dispatch) => {
   
   
    return post(PrimaryMotivationlAdd, data, loader).then((res) => {
       
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};



/******************** 
@purpose : Used for Get Service Agreement 
@Parameter : { data }
@Author : INIC
******************/

export const getServiceAgreementList = (loader ) => (dispatch) => {
   
    return get(ServiceAgreement, loader).then((res) => {
        dispatch(getServiceAgreement(res));
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Sending Service Agreement Details 
@Parameter : { data }
@Author : INIC
******************/
export const sendServiceAgreementData = (data,loader ) => (dispatch) => {
     
    return post(ServiceAgreementData, data, loader).then((res) => {
       
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Forgot Password 
@Parameter : { data }
@Author : INIC
******************/
export const forgotPassword = (data,loader ) => (dispatch) => {
       return post(FORGOT, data, loader).then((res) => {
             
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};


/******************** 
@purpose : Used for Reset Password 
@Parameter : { data }
@Author : INIC
******************/
export const resetPassword = (data,loader ) => (dispatch) => {
       
    return post(RESETPASSWORD, data, loader).then((res) => {
             
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Reset Password 
@Parameter : { data }
@Author : INIC
******************/
export const changePassword = (data,loader ) => (dispatch) => {
       
    return post(CHANGEPASSWORD, data, loader).then((res) => {
             
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};


/******************** 
@purpose : Used for Profile Data to api 
@Parameter : { data }
@Author : INIC
******************/
export const sendProfileData = (data,loader ) => (dispatch) => {
   
    return formdata(ProfileData, data, loader).then((res) => {
         
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Signature Data to api 
@Parameter : { data }
@Author : INIC
******************/
export const sendSignatureData= (data,loader ) => (dispatch) => {
   
    return formdata(SignatureData, data, loader).then((res) => {
         
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Get Stripe Connect 
@Parameter : { data }
@Author : INIC
******************/
export const stripeConnect = (data,loader ) => (dispatch) => {
       
    return get(StripeConnect+`?email=${data.email}`, data, loader).then((res) => {
             
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        
        return res;
    });
};
/******************** 
@purpose : Used for Stripe Connect 
@Parameter : { data }
@Author : INIC
******************/
export const stripeConnectSend = (data,loader ) => (dispatch) => {
       
    return get(StripeConnectSend+`?code=${data.code}&state=${data.state}`, data, loader).then((res) => {
             
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};