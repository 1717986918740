import React from "react";
import Header from "./Header.jsx";
function Layout(props) {
  /******************* 
@Purpose : Used for render HTML/Components
@Parameter : {}
@Author : INIC
******************/
  return (
    <div className="container-scroller">
      <Header />
      <main className={`main-layout `}>{props.children}</main>
    </div>
  );
}

export default Layout;
