import React from "react";
import * as yup from "yup";
import EM from "./ErrorMessages";
import {
  validateEmail,
  validatePassword,
  validateMobileNumber,
} from "./Functions";
/******************* 
@purpose :Onboarding Step1 Signup page Validation Schema
@Author : INIC
******************/
export const signupSchema = yup.object().shape({
  Title: yup.string().trim().required(EM.REQUIRED),
  FirstName: yup.string().trim().required(EM.REQUIRED),
  LastName: yup.string().trim().required(EM.REQUIRED),
  BirthDate: yup.string().trim().required(EM.REQUIRED),
  Email: yup
    .string()
    .trim()
    .required(EM.PROVIDE_EMAIL)
    .matches(validateEmail, EM.PROVIDE_VALID_MO_EMAIL),
  Password: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .matches(validatePassword, EM.PROVIDE_VALID_PASSWORD),
  ConfirmPassword: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .oneOf([yup.ref("Password"), null], EM.PASSWORD_NOT_MATCHED),
});

/******************* 
@purpose :Onboarding Step 2 Validation Schema
@Author : INIC
******************/
export const step2Schema = yup.object().shape({
  ProfessionId: yup.string().trim().required(EM.REQUIRED),
  GMCReferenceNumber: yup.string().trim().required(EM.REQUIRED),
  image: yup.mixed().required(EM.REQUIRED),
});
/******************* 
@purpose :Onboarding Step 3 Validation Schema
@Author : INIC
******************/
export const step3Schema = yup.object().shape({
  CountryCode: yup.string().trim().required(EM.REQUIRED),
  PhoneNumber: yup
    .string()
    .required(EM.REQUIRED)
    .matches(validateMobileNumber, EM.PROVIDE_VALID_PHONE),
});
/******************* 
@purpose :Onboarding Step 4 Address Validation Schema
@Author : INIC
******************/
export const step4Schema = yup.object().shape({
  Address1: yup.string().required(EM.REQUIRED),
  City: yup.string().required(EM.REQUIRED),
  Country: yup.string().required(EM.REQUIRED),
});

/******************* 
@purpose :Onboarding Step5 Validation Schema
@Author : INIC
******************/
export const step5Schema = yup.object().shape({
  PrimaryMotivationId: yup.string().required(EM.REQUIRED),
});
/******************* 
@purpose :Login Page Validation Schema
@Author : INIC
******************/
export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required(EM.PROVIDE_EMAIL)
    .matches(validateEmail, EM.PROVIDE_VALID_MO_EMAIL),
  password: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    // .matches(validatePassword, EM.PROVIDE_VALID_PASSWORD),
});
/******************* 
@purpose :Forgot Password Page Validation Schema
@Author : INIC
******************/

export const forgotSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required(EM.PROVIDE_EMAIL)
    .matches(validateEmail, EM.PROVIDE_VALID_MO_EMAIL),
});

/******************* 
@purpose :Reset Password Page Validation Schema
@Author : INIC
******************/
export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .matches(validatePassword, EM.PROVIDE_VALID_PASSWORD),
    confirmPassword: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .oneOf([yup.ref("password"), null], EM.PASSWORD_NOT_MATCHED),
});

/******************* 
@purpose :Change Password Page Validation Schema
@Author : INIC
******************/
export const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .matches(validatePassword, EM.PROVIDE_VALID_PASSWORD),
    newPassword: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .matches(validatePassword, EM.PROVIDE_VALID_PASSWORD),
    confirmNewPassword: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .oneOf([yup.ref("newPassword"), null], EM.PASSWORD_NOT_MATCHED),
});

/******************* 
@purpose :Profile page Validation Schema
@Author : INIC
******************/
export const profileSchema = yup.object().shape({
  Title: yup.string().trim().required(EM.REQUIRED),
  FirstName: yup.string().trim().required(EM.REQUIRED),
  LastName: yup.string().trim().required(EM.REQUIRED),
  PhoneNumber: yup
    .string()
    .required(EM.REQUIRED)
    .matches(validateMobileNumber, EM.PROVIDE_VALID_PHONE),
  Email: yup
    .string()
    .trim()
    .required(EM.PROVIDE_EMAIL)
    .matches(validateEmail, EM.PROVIDE_VALID_MO_EMAIL),
  Skype: yup.string().trim().required(EM.REQUIRED),
  Profession:yup.string().trim().required(EM.REQUIRED),
  gmcReferenceNumber:yup.string().trim().required(EM.REQUIRED),
  Address1: yup.string().required(EM.REQUIRED),
  City: yup.string().required(EM.REQUIRED),
  State: yup.string().required(EM.REQUIRED),
  Country: yup.string().required(EM.REQUIRED),
  Postcode:yup.string().required(EM.REQUIRED),
});
