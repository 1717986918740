import { API_URL } from '../config/index'; // project configuration file
import fetch from 'isomorphic-unfetch';
import {store} from '../store'; // Access redux store data
import {toggleLoader } from '../store/Actions';
import { alertDialogue } from '../utils';
import axios from "axios";
/******************* 
@purpose : Convert Responce to JSON
@Parameter : {response, status}
@Author : INIC
******************/
const makeJson = async (response, status) => {
    const json = response.json
        ? await response.json()
        : (await response?.response?.data) || (await response);
    return Promise.resolve({ ...json, statusCode: status });
};
/******************* 
@purpose : API Responce Handler
@Parameter : {loader, alert, res}
@Author : INIC
******************/
const responseHandler = (loader, alert) => (res) => {
    let promise = makeJson(res, res.status || res?.response?.status);
    promise
        .then((response) => {
            loader && store.dispatch(toggleLoader(false, loader));
               console.log("response",response)

        })
        .catch((_err) => {
            alertDialogue('Something Went Wrong ...');
        });
    return promise;
};
/******************* 
@purpose : API Request Header Types
@Parameter : {}
@Author : INIC
******************/
const getHeader = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...((store.getState().user?.userInfo?.jwtToken) && {
        Authorization: `Bearer ${store.getState().user?.userInfo?.jwtToken}`,
    }),
});
/******************* 
@purpose : Fetch Get API
@Parameter : {data}
@Author : INIC
******************/
export const get = (...data) => {
    const [url, loader = 'page', alert = false] = data;
    loader && store.dispatch(toggleLoader(true, loader));
    return fetch(`${API_URL}/${url}`, {
        method: 'get',
        headers: getHeader(),
    })
        .then(responseHandler(loader, alert))
        .catch(responseHandler(loader, alert));
};
/******************* 
@purpose : Fetch Post API
@Parameter : {data}
@Author : INIC
******************/
export const post = (...data) => {
    const [url, body,loader = 'page', alert = true] = data;
    loader && store.dispatch(toggleLoader(true, loader));
     return fetch(`${API_URL}/${url}`, {
        method: 'post',
        headers: getHeader(),
        body: JSON.stringify(body),
    })
        .then(responseHandler(loader, alert))
        .catch(responseHandler(loader, alert));
};
/******************* 
@purpose : Fetch Post API
@Parameter : {data}
@Author : INIC
******************/
export const formdata = (...data) => {
    const [url, body, loader = 'page', alert = true] = data;
    loader && store.dispatch(toggleLoader(true, loader));
    return axios.post(`${API_URL}/${url}`, body, {
        
         headers: getHeader()
         })
        .then(responseHandler( loader, alert))
        .catch(responseHandler(loader, alert));
};
/******************* 
@purpose : Fetch Put API
@Parameter : {data}
@Author : INIC
******************/
export const put = (...data) => {
    const [url, body, loader = 'page', alert = true] = data;
    loader && store.dispatch(toggleLoader(true, loader));
    return fetch(`${API_URL}/${url}`, {
        method: 'put',
        headers: getHeader(),
        body: JSON.stringify(body),
    })
        .then(responseHandler(loader, alert))
        .catch(responseHandler(loader, alert));
};
/******************* 
@purpose : Fetch Delete API
@Parameter : {data}
@Author : INIC
******************/
export const remove = (...data) => {
    const [url, loader = 'page', alert = true] = data;
    loader && store.dispatch(toggleLoader(true, loader));
    return fetch(`${API_URL}/${url}`, {
        method: 'delete',
        headers: getHeader(),
    })
        .then(responseHandler(loader, alert))
        .catch(responseHandler(loader, alert));
};
/******************* 
@purpose : Fetch Patch API
@Parameter : {data}
@Author : INIC
******************/
export const patch = (...data) => {
    const [url, body, loader = 'page', alert = true] = data;
    return fetch(`${API_URL}/${url}`, {
        method: 'patch',
        headers: getHeader(),
        body: JSON.stringify(body),
    })
        .then(responseHandler(loader, alert))
        .catch(responseHandler(loader, alert));
};


