/******************* 
@Purpose :  We can use following function to use localstorage
@Author : INIC
******************/
export default {
    REQUIRED: "*Field Required",
    PROVIDE_EMAIL: "*Please enter email",
    PROVIDE_VALID_MO_EMAIL:'*Please provide valid email',
    PROVIDE_PASSWORD: "*Field Required",
    PROVIDE_VALID_PASSWORD: "*A minimum 6 characters password contains a combination of Uppercase,Lowercase letter,Special character and Number",
    PASSWORD_NOT_MATCHED:"*Password and confirm password does not match",
    PROVIDE_VALID_PHONE: "*Please Provide Valid Phone No.",
}
  