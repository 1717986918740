import React from "react";

import { FormGroup, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "../../components/inputs/textfield";
import { Link } from "react-router-dom";
import DateTime from "react-datetime";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import moment from "moment";
function AssignedTasks(_props) {
  const formik = useFormik({
    initialValues: {
      selectProfession: "",
      BirthDate: "",
      registration: "",
    },
    onSubmit(values) {},
  });
  /******************* 
@purpose : Render UI For Assigned Tasks 
@Author  : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content pb-5">
        <div className="big-heading h3">Assigned Tasks</div>
        <Row>
          <Col xs={12} md={4}>
            <FormGroup className="form-group input-ic-wrap">
              <TextField
                type="text"
                placeholder="Filter"
                name="Filter"
                formik={formik}
                id="Filter"
                showUntoucherError
              />
              <i className="icon-search" aria-hidden="true"></i>
            </FormGroup>
          </Col>
          <Col xs={12} md={4}>
            <FormGroup
              className={`form-group input-ic-wrap  ${
                formik.values.BirthDate ? "date-filled" : "date-empty"
              }`}
            >
              <DateTime
                name="BirthDate"
                placeholder="Date of birth"
                value={formik.values.BirthDate}
                locale="en-US"
                dateFormat="DD/MM/YYYY"
                closeOnSelect
                timeFormat={false}
                onChange={(e) => {
                  var dboData = moment(e).format("YYYY-MM-DD");
                  formik?.setFieldValue("BirthDate", dboData);
                }}
                inputProps={{
                  placeholder: "Date of birth",
                }}
              />
              <i className="icon-calender" aria-hidden="true"></i>
            </FormGroup>
          </Col>
        </Row>
        <Tabs defaultActiveKey="TaskRequest" className="">
          <Tab eventKey="TaskRequest" title="Task Request">
            <div className="pendingTask-wrap pt-4">
              <Row className="pendingTask-block">
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Task Request
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Available Payment:{" "}
                        <span className="b-700 primary-text">￡30.00</span>
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <div className="task-action">
                        <Link to="/" className="sm-text b-700 primary-text">
                          Accept
                        </Link>
                        <Link to="/" className="sm-text b-700 black-text">
                          Reject
                        </Link>
                      </div>
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Task Request
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Available Payment:{" "}
                        <span className="b-700 primary-text">￡30.00</span>
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <div className="task-action">
                        <Link to="/" className="sm-text b-700 primary-text">
                          Accept
                        </Link>
                        <Link to="/" className="sm-text b-700 black-text">
                          Reject
                        </Link>
                      </div>
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Task Request
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Available Payment:{" "}
                        <span className="b-700 primary-text">￡30.00</span>
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <div className="task-action">
                        <Link to="/" className="sm-text b-700 primary-text">
                          Accept
                        </Link>
                        <Link to="/" className="sm-text b-700 black-text">
                          Reject
                        </Link>
                      </div>
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Task Request
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Available Payment:{" "}
                        <span className="b-700 primary-text">￡30.00</span>
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <div className="task-action">
                        <Link to="/" className="sm-text b-700 primary-text">
                          Accept
                        </Link>
                        <Link to="/" className="sm-text b-700 black-text">
                          Reject
                        </Link>
                      </div>
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="Pending" title="Pending">
            <div className="pendingTask-wrap pt-4">
              <Row className="pendingTask-block">
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Esther Howard
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <div className="task-action">
                        <Link to="/" className="sm-text b-700 primary-text">
                          Complete
                        </Link>
                      </div>
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Esther Howard
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <div className="task-action">
                        <Link to="/" className="sm-text b-700 primary-text">
                          Complete
                        </Link>
                      </div>
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Esther Howard
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <div className="task-action">
                        <Link to="/" className="sm-text b-700 primary-text">
                          Complete
                        </Link>
                      </div>
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="Complete" title="Complete">
            <div className="pendingTask-wrap pt-4">
              <Row className="pendingTask-block">
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Friday Jan 4, 2022
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Friday Jan 4, 2022
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Friday Jan 4, 2022
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Friday Jan 4, 2022
                      </div>
                      <div className="sm-text pendingTask-text">
                        Time left to complete:{" "}
                        <span className="b-700 primary-text">1h 23m 43s</span>
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="Archived" title="Archived">
            <div className="pendingTask-wrap pt-4">
              <Row className="pendingTask-block">
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Friday Jan 4, 2022
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Esther Howard
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Friday Jan 4, 2022
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Esther Howard
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Friday Jan 4, 2022
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Esther Howard
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div className="pendingTask-tile black-text">
                    <div className="pendingTask-inner-tile">
                      <div className="lg-text b-700 mb-2 pendingTask-heading">
                        Wellness Pro Report
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Friday Jan 4, 2022
                      </div>
                      <div className="sm-text mb-2 pendingTask-text">
                        Esther Howard
                      </div>
                    </div>
                    <div className="pendingTask-foter">
                      <Link
                        to="/report/current-report"
                        className="sm-text grey-text clientCheck-details"
                      >
                        Check details <span className="icon-arrow-right"></span>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>
        </Tabs>
      </div>
    </Layout>
  );
}
export default AssignedTasks;
