import React from "react";
import BtnMain from "../buttons/btn_main";
import { Form, FormGroup } from "react-bootstrap";
function AddNote() {
  /******************* 
@purpose : used for Accept Button Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleClick = (e) => {
    e.preventDefault();
  };

  /******************* 
    @purpose : Service Agreement Modal Component
    @Parameter : {} 
    @Author : INIC
    ******  ************/
  return (
    <>
      <FormGroup className="form-group">
        <div className="form-label mb-1 b-700 sm-text">Add note</div>
        <Form.Control as="textarea" rows={3} />
      </FormGroup>

      <div className=" text-center">
        <BtnMain
          text="Done"
          className="btn btn-primary fixWidth-btn"
          type="button"
          onClick={handleClick}
        />
      </div>
    </>
  );
}
export default AddNote;
