import React from "react";

function StripePayment(_props) {
  /******************* 
@Purpose : Used for render UI
@Parameter : {}
@Author : INIC
******************/

  return <div className="main-layout"></div>;
}
export default StripePayment;
