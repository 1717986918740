import React from "react";

import { Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfilePhoto from "./../../../assets/images/temp-img/profile_photo.png";
import questionMark from "./../../../assets/images/color-icon/question-mark.svg";
import Layout from "../../../components/Layout/dashboard-layout/Layout";
import BtnMain from "../../../components/buttons/btn_main";
import Covid19_antibodies from "./../../../assets/images/temp-img/Covid19_antibodies.jpeg";
function CurrentReport(_props) {
  /******************* 
@Purpose : Used for render UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <Layout>
      <div className="dashboard-inner-content">
        <Breadcrumb>
          <Breadcrumb.Item href="/assigned-tasks" className="sm-text ">
            Assigned Tasks <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="sm-text">
            Sarah Jack
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="current-report-wrap">
          <div className="currentReportProfile-block">
            <div className="user-block user-block-lg">
              <div className="userImg-block">
                <div
                  className="user-img"
                  style={{ backgroundImage: "url(" + ProfilePhoto + ")" }}
                ></div>
              </div>
              <div className="user-text">
                <div className="h3 mb-4 black-text b-700">Sarah Jack</div>
                <div className="tag-block blue-tag-block">
                  <div className="tag-tile">Female</div>
                  <div className="tag-tile">24 Years Old</div>
                  <div className="tag-tile">69 kg</div>
                  <div className="tag-tile">White</div>
                  <div className="tag-tile">United Kingdom</div>
                  <div className="tag-tile">Drinker</div>
                  <div className="tag-tile">Smoker</div>
                  <div className="tag-tile">Self-employed</div>
                  <div className="tag-tile">Alergies</div>
                  <div className="tag-tile">Asthma</div>
                </div>
              </div>
            </div>
            <div className="currentReportProfile-action d-flex">
              <Link
                to={"/prescriber/user-profile"}
                className="btn gray-line-btn "
              >
                View{" "}
              </Link>
            </div>
          </div>
          <div className="profileInfo-wrap">
            <div className="grey-text xs-text b-700 profileInfo-heading">
              Pharmacy Medicine Consultation{" "}
            </div>
            <div className="profileInfo-block black-text">
              <div className="profileInfo-tile">
                <div className="user-block">
                  <div className="userImg-block ">
                    <div className="userIc light-bg">
                      <img src={questionMark} alt={questionMark} />
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Pharmacy Medicine Consultation
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      June 23, 2021 at 21:34
                    </div>
                  </div>
                </div>
                <div className="profileInfo-action d-flex">
                  <Link to={"/"} className="btn gray-line-btn ">
                    View{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="order-wrap pb-5">
            <div className="grey-text xs-text b-700 order-heading mb-3">
              Client cart{" "}
            </div>
            <Row className="">
              <Col className="" sm={12} md={6} lg={6} xl={4}>
                <div className="order-block">
                  <div className="order-img-block ">
                    <div
                      className="order-img "
                      style={{
                        backgroundImage: "url(" + Covid19_antibodies + ")",
                      }}
                    ></div>
                  </div>
                  <div className="order-text ">
                    <div className="order-heading lg-text b-700 mb-2">
                      Blood Test Subscription
                    </div>
                    <div className="order-subheading sm-text grey-text mb-2">
                      Every 3 Months
                    </div>
                  </div>
                  <div className="order-footer">
                    <div className="order-price">
                      <span className="new-price me-2 lg-text b-700">
                        ￡120
                      </span>
                      <span className="old-price xs-text me-2 grey-text">
                        ￡149{" "}
                      </span>{" "}
                      <span className="discount-price xs-text black-text">
                        (57% off)
                      </span>
                    </div>
                    <div className="order-action quantity-wrap">
                      <div className="dec-quantity">
                        <span className="icon-minus"></span>
                      </div>
                      <div className="orderCart-number sm-text black-text">
                        2
                      </div>
                      <div className="inc-quantity">
                        <span className="icon-plus"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="" sm={12} md={6} lg={6} xl={4}>
                <div className="order-block">
                  <div className="order-img-block ">
                    <div
                      className="order-img "
                      style={{
                        backgroundImage: "url(" + Covid19_antibodies + ")",
                      }}
                    ></div>
                  </div>
                  <div className="order-text ">
                    <div className="order-heading lg-text b-700 mb-2">
                      Atorvastatin calcium 40 mg
                    </div>
                    <div className="order-subheading sm-text grey-text mb-2">
                      Atorvastatin belongs to a group of drugs called HMG CoA
                      reductase inhibitors, or "statins."
                    </div>
                  </div>
                  <div className="order-footer">
                    <div className="order-price">
                      <span className="new-price me-2 lg-text b-700">
                        ￡120
                      </span>
                      <span className="old-price xs-text me-2 grey-text">
                        ￡149{" "}
                      </span>{" "}
                      <span className="discount-price xs-text black-text">
                        (57% off)
                      </span>
                    </div>
                    <div className="order-action quantity-wrap">
                      <div className="dec-quantity">
                        <span className="icon-minus"></span>
                      </div>
                      <div className="orderCart-number sm-text black-text">
                        2
                      </div>
                      <div className="inc-quantity">
                        <span className="icon-plus"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="" sm={12} md={6} lg={6} xl={4}>
                <div className="order-block">
                  <div className="order-img-block ">
                    <div
                      className="order-img "
                      style={{
                        backgroundImage: "url(" + Covid19_antibodies + ")",
                      }}
                    ></div>
                  </div>
                  <div className="order-text ">
                    <div className="order-heading lg-text b-700 mb-2">
                      Hydrochlorothiazide 25 mg
                    </div>
                    <div className="order-subheading sm-text grey-text mb-2">
                      HCTZ (hydrochlorothiazide) is a thiazide diuretic (water
                      pill) that helps prevent your body from absorbing too much
                      salt, which can cause fluid retention.
                    </div>
                  </div>
                  <div className="order-footer">
                    <div className="order-price">
                      <span className="new-price me-2 lg-text b-700">
                        ￡120
                      </span>
                      <span className="old-price xs-text me-2 grey-text">
                        ￡149{" "}
                      </span>{" "}
                      <span className="discount-price xs-text black-text">
                        (57% off)
                      </span>
                    </div>
                    <div className="order-action quantity-wrap">
                      <div className="dec-quantity">
                        <span className="icon-minus"></span>
                      </div>
                      <div className="orderCart-number sm-text black-text">
                        2
                      </div>
                      <div className="inc-quantity">
                        <span className="icon-plus"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="dashboard-footer">
          <BtnMain
            text="Decline"
            className="btn gray-line-btn me-4 fixWidth-btn"
            type="button"
          />
          <BtnMain
            text="Generate Prescription"
            className="btn btn-success "
            type="button"
          />
        </div>
      </div>
    </Layout>
  );
}
export default CurrentReport;
