import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, googleLogin } from "../../store/Actions";
import { useFormik } from "formik";
import { loginSchema } from "../../utils/schema";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";
import { Form, FormGroup, Container } from "react-bootstrap";
import "react-datetime/css/react-datetime.css";
import GoogleLogin from "react-google-login";
import { GOOGLE_ID } from "../../config/index";
import TextField from "../../components/inputs/textfield";
import BtnMain from "../../components/buttons/btn_main";
import Loader from "../../components/Layout/loader/Loader";
import { useHistory } from "react-router-dom";
function Login(props) {
  let history = useHistory();
  const isLoading = useSelector((state) => state?.ui?.loaders?.login);
  const [error, setError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit(values) {
      const body = values;
      dispatch(login(body, "login")).then((res) => {
        if (res.success) {
          history.push("/dashboard");
          setError("");
        } else setError(res.errorMessage);
      });
    },
    validationSchema: loginSchema,
  });
  /******************* 
@purpose : used for Form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };
  /******************* 
@purpose : used for google login Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSocialLogin = (type, _user) => {
    dispatch(googleLogin(type, "login")).then((_res) => {
      props.goToStep(2);
    });
  };

  /******************* 
@purpose : Render Login Page UI 
@Author  : INIC
******************/

  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <Container className="auth-main">
        <div className="auth-box">
          <div className="auth-heading">
            Welcome back! <br />
            It's great to see you again.
          </div>
          <GoogleLogin
            className="googlesignin-box"
            icon={false}
            clientId={GOOGLE_ID}
            buttonText="Sign In with Google"
            onSuccess={(user) => handleSocialLogin("google", user)}
            onFailure={(errors) => handleSocialLogin("google", errors)}
          />
          <div className="or-text">
            or
            {error && <div className="message-block">{error}</div>}
          </div>

          <div className="form-wrap">
            <Form onSubmit={handleSubmit} noValidate>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type="email"
                  placeholder="Email address"
                  name="email"
                  formik={formik}
                  id="email"
                  showUntoucherError
                />
                <i className="icon-envelope-alt" aria-hidden="true"></i>
              </FormGroup>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  formik={formik}
                  id="password"
                  showUntoucherError
                />
                <i
                  className={`${
                    passwordShown ? "icon-eye-slash" : "icon-eye"
                  } toggle-password`}
                  aria-hidden="true"
                  onClick={togglePasswordVisiblity}
                ></i>
              </FormGroup>
              <FormGroup className="form-group">
                <div className="condition-text text-end">
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
              </FormGroup>
              <FormGroup className="form-group mb-0">
                <BtnMain
                  text="Sign in"
                  className={
                    !(formik.isValid && formik.dirty)
                      ? "btn black-btn w-100 disabled-btn"
                      : "btn black-btn w-100"
                  }
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                />
              </FormGroup>
            </Form>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
export default Login;
