import * as types from './Types'; // Redux actions payload types

/******************** 
@purpose : Used for Storing user info
@Parameter : { }
@Author : INIC
******************/
export const setUserInfo = (data) => ({
    type: types.SET_USER_INFO,
    data,
});
/******************** 
@purpose : Used for Storing Updated User info
@Parameter : { }
@Author : INIC
******************/
export const setUserInfoUpdated = (data) => ({
    type: types.SET_USER_INFO_UPDATED,
    data,
});
/******************** 
@purpose : Used to get ProfessionalInfo list and store in redux
@Parameter : {data }
@Author : INIC
******************/
export const getProfessionalInfo = (data) => ({
    type: types.SET_PROFESSIONAL_INFO,
    data,
});

/******************** 
@purpose : Used to get Country Code list and store in redux
@Parameter : {data }
@Author : INIC
******************/
export const getCountryCodeInfo = (data) => ({
    type: types.SET_COUNTRY_CODE_INFO,
    data,
});


/******************** 
@purpose : Used to get ProfessionalInfo list and store in redux
@Parameter : {data }
@Author : INIC
******************/
export const getMotivationalInfo = (data) => ({
    type: types.SET_MOTIVATIONAL_INFO,
    data,
});


/******************* 
@purpose :  Handle Last Step View
@Parameter : {value, key}
@Author : INIC
******************/
export const setLastCompletedStep = (value, key) => ({
    type: types.SET_LAST_COMPLETED_STEP,
    value,
    key,
  });
  
/******************** 
@purpose : Used to get Service Agreement
@Parameter : {data }
@Author : INIC
******************/
export const getServiceAgreement = (data) => ({
    type: types.SET_SERVICE_AGREEMENT_INFO,
    data,
});