import React from "react";
import { Breadcrumb } from "react-bootstrap";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import BtnMain from "../../components/buttons/btn_main";
import ThanksImg from "./../../assets/images/thanks-img.svg";
function Thanks(props) {
  /******************* 
@Purpose : Used for render UI
@Parameter : {}
@Author : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content">
        <Breadcrumb>
          <Breadcrumb.Item href="/assigned-tasks" className="sm-text ">
            Assigned Tasks <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/report/current-report" className="sm-text ">
            Sarah Jack <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>

          <Breadcrumb.Item active className="sm-text">
            Lifestyle and Behaviour Quiz
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="thanks-wrap">
          <div className="thanks-img">
            <img src={ThanksImg} alt={ThanksImg} />
          </div>
          <div className="thanks-text">
            <div className="thanks-heading h3 b-800">Completed</div>
            <div className="thanks-subHeading">This task is complete</div>
          </div>
        </div>
        <div className="dashboard-footer">
          <BtnMain
            text="View next task"
            className="btn btn-primary me-4 "
            type="button"
          />
        </div>
      </div>
    </Layout>
  );
}
export default Thanks;
