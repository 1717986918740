/******************* 
@Purpose : We can use following api calls and can change api name easily
@Author : INIC
******************/
export default {
    //Authentication 
    LOGIN: 'Authentication/Login',
    REGISTER: "PartnerRegistration/CreateAccount",
    FORGOT: "Password/ForgotPassword",
    RESETPASSWORD:"Password/PasswordRecoveryConfirm",
    CHANGEPASSWORD:"Password/ChangePassword",
    GOOGLEREGISTER: "PartnerRegistration/CreateAccount",
    ProfessionalList: "PartnerRegistration/GetPartnerProfessionList",
    ProfessionalData: "PartnerRegistration/AddPartnerProfessionDeatils",
    CountryCodeList: "PartnerRegistration/GetCounrtyphonecodeList",
    ProfessionalNo: "PartnerRegistration/AddPartnerProfessionPhoneDeatils",
    ProfessionalAddress: "PartnerRegistration/AddPartnerProfessionAddressDeatils",
    PrimaryMotivationlList:"PartnerRegistration/GetPartnerPrimaryMotivationList",
    PrimaryMotivationlAdd:"PartnerRegistration/AddPartnerPrimaryMotivation",
    ServiceAgreement:"PartnerRegistration/GetaddServiceAgreement",
    ServiceAgreementData:"PartnerRegistration/addServiceAgreement",
    SignatureData:"PartnerRegistration/AddPartnerSignature",
    ProfileData:"PartnerRegistration/ProfileData",
    StripeConnect:"Stripe/GetConnectRedirectUrlEmail",
    StripeConnectSend:"Stripe/CompleteStripeConnectAccount",
 
}

  