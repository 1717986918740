import React, { Fragment } from "react";
import { FormControl } from "react-bootstrap";

const TextField = (props) => {
  const {
    label,
    placeholder,
    type,
    name,
    labelClassName,
    formik,
    inputRef,
    showUntoucherError = false,
    shouldShowErrorIcon = true,
    showError = true,
    ...otherProps
  } = props;
  /******************* 
    @purpose : Render TextField Component
    @Parameter : {} 
    @Author : INIC
    ******************/

  return (
    <Fragment>
      {label && <label className={labelClassName}>{label}</label>}
      <FormControl
        ref={inputRef}
        type={type}
        placeholder={placeholder}
        className={`${
          formik && formik.values[name]?.length > 0 && "input-filled"
        } ${!shouldShowErrorIcon ? "hide-error-icon" : ""}`}
        {...(formik && formik.getFieldProps(name))}
        isInvalid={
          formik &&
          (formik.touched[name] || showUntoucherError) &&
          formik.errors[name]
        }
        {...otherProps}
      />
      {showError &&
        formik &&
        formik["errors"][name] &&
        (formik.touched[name] || showUntoucherError) && (
          <div className="message-block">{formik["errors"][name]}</div>
        )}
    </Fragment>
  );
};
export default TextField;
