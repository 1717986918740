import React from "react";
import { Nav } from "react-bootstrap";

function SideBar(props) {
  /******************* 
@Purpose : Used for render HTML/Components
@Parameter : {}
@Author : INIC
******************/
  return (
    <>
      <div
        className={`d-flex flex-column flex-shrink-0 sidebar-block ${
          props.sidebar ? "sidebar-show" : ""
        }`}
      >
        <Nav className=" nav-pills flex-column mb-auto">
          <Nav.Item>
            <Nav.Link href="/dashboard">
              <span className="icon-home"></span> Dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Link href="/assigned-tasks">
            <span className="icon-check-circle"></span> Assigned Tasks
          </Nav.Link>
          <Nav.Link href="#home">
            <span className="icon-smile"></span> Clients / Patients
          </Nav.Link>
          <Nav.Link href="#home">
            <span className="icon-calendar-alt"></span> Appointments
          </Nav.Link>
          <Nav.Link href="#home">
            <span className="icon-comment-message"></span> Messaging
          </Nav.Link>
          <Nav.Link href="#home">
            <span className="icon-file-alt"></span> Resources
          </Nav.Link>
          <Nav.Link href="#home">
            <span className="icon-store"></span> Store
          </Nav.Link>
          <Nav.Link href="#home">
            <span className="icon-bell"></span> Notification
          </Nav.Link>
          <Nav.Link href="#home">
            <span className="icon-share-alt"></span> Affliate Program
          </Nav.Link>
          <Nav.Link href="#home">
            <span className="icon-bag"></span> Kit Registration
          </Nav.Link>
          <Nav.Link href="#home">
            <span className="icon-postcard"></span> Payments
          </Nav.Link>
          <Nav.Link href="#home">
            <span className="icon-cog"></span> Support
          </Nav.Link>
        </Nav>
      </div>
    </>
  );
}

export default SideBar;
