import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "../Layout/Layout";
import { step5Schema } from "../../utils/schema";
import Select from "react-select";
import {
  getPrimaryMotivationList,
  sendPrimaryMotivation,
  setLastCompletedStep,
} from "../../store/Actions";
import Loader from "../Layout/loader/Loader";
import BtnMain from "../buttons/btn_main";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup } from "react-bootstrap";
export default function Step5(props) {
  let history = useHistory();

  const isLoading = useSelector((state) => state?.ui?.loaders?.step5);
  const motivationlInfo = useSelector(
    (state) => state?.user?.motivationPartnerListInfo
  );
  const userInfoUpdated = useSelector(
    (state) => state?.user?.userInfoUpdated?.data?.data
  );
  const [error, setError] = useState("");
  const [motivation, setMotivation] = useState("");
  var length = Object.keys(motivationlInfo).length;
  /*** 
       @purpose :hooks used for api call
      @Parameter : {} 
      @Author : INIC
      **/
  useEffect(() => {
    const body = "";
    dispatch(getPrimaryMotivationList(body));
  }, []);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      PrimaryMotivationId: "",
      Extra: "",
    },
    onSubmit(values) {
      const body = values;
      body.id = userInfoUpdated.id;
      body.userId = userInfoUpdated.userId;
      dispatch(sendPrimaryMotivation(body, "step5")).then((res) => {
        if (res.success) {
          dispatch(setLastCompletedStep(true, "professionalDetails"));
          history.push("/last-step");
        } else {
          setError(res.errorMessage);
        }
      });
    },
    validationSchema: step5Schema,
  });

  /******************* 
@purpose : used for Form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };
  /******************* 
/******************* 
@purpose : used for Profesional List Array
@Author : INIC
******************/
  const getrows = [];
  if (length > 0) {
    var i = 1;
    Object.keys(motivationlInfo).forEach(function (key) {
      if (i < length) {
        getrows.push({
          value: motivationlInfo[key].id,
          label: motivationlInfo[key].name,
        });
      }
      i++;
    });
  }
  const SelectPrimaryMotivation = getrows;
  /******************* 
@purpose : Render Step 5 UI For Onboarding 
@Author  : INIC
******************/
  return (
    <Layout>
      <div className="step-main-wrap">
        <Form onSubmit={handleSubmit} noValidate>
          <div className="progress-baar">
            <div
              className="progress-baar-value"
              style={{ width: "100%" }}
            ></div>
          </div>
          {isLoading && <Loader text="Loading" />}
          <div className="createAcount-wrap">
            <div className="step-wrap">
              <div className="step-heading">
                This helps us understand your goals and allows us to create an
                experience that suits you best.
              </div>
              <div className="step-idea-box">
                <div className="step-idea-ic">
                  <i className="icon-lightbulb-alt"></i>
                </div>
                <div className="step-idea-text">
                  We use your address to ship DNA and Blood tests Kits
                </div>
              </div>
              {error && <div className="message-block">{error}</div>}
              <FormGroup className="form-group ">
                <div className="step-form-wrap">
                  <Select
                    classNamePrefix="custom-select"
                    className=""
                    placeholder="Select Primary Motivation"
                    type="text"
                    id="SelectPrimaryMotivation"
                    name="PrimaryMotivationId"
                    onChange={(e) => {
                      formik?.setFieldValue("PrimaryMotivationId", e.value);
                      setMotivation(e.label);
                    }}
                    options={SelectPrimaryMotivation}
                  />
                  {formik.errors.PrimaryMotivationId ? (
                    <div className="message-block">
                      {formik.errors.PrimaryMotivationId}
                    </div>
                  ) : null}
                </div>
              </FormGroup>
              {motivation === "SomethingElse" ? (
                <FormGroup className="form-group ">
                  <p> Please elaborate</p>
                  <textarea
                    placeholder="Message"
                    name="Extra"
                    className="form-control"
                    onChange={(e) => {
                      formik?.setFieldValue("Extra", e.target.value);
                    }}
                  ></textarea>
                </FormGroup>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="step-footer">
            <div className="step-footer-cotainer">
              <BtnMain
                text="Back"
                className="btn gray-line-btn me-4"
                onClick={() => props.goToStep(3)}
                type="button"
              />
              <BtnMain text="Next" className="btn btn-primary" type="submit" />
            </div>
          </div>
        </Form>
      </div>
    </Layout>
  );
}
