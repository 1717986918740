import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../store/Actions";
import { useFormik } from "formik";
import { signupSchema } from "../../utils/schema";
import Layout from "../Layout/Layout";
import Select from "react-select";
import { Link } from "react-router-dom";
import TextField from "../inputs/textfield";
import { Form, FormGroup, Container } from "react-bootstrap";
import BtnMain from "../buttons/btn_main";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import Loader from "../Layout/loader/Loader";
import GoogleLogin from "react-google-login";
import { GOOGLE_ID } from "../../config/index";

function Step1(props) {
  const isLoading = useSelector((state) => state?.ui?.loaders?.register);
  const [error, setError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };
  const titleOptions = [
    { value: "", label: "Select Title" },
    { value: "Prof", label: "Prof" },
    { value: "Dr", label: "Dr." },
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
    { value: "Miss", label: "Miss" },
  ];
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      Title: "",
      FirstName: "",
      LastName: "",
      BirthDate: "",
      Email: "",
      Password: "",
      ConfirmPassword: "",
      ExternalAuth: false,
    },
    onSubmit(values) {
      const body = values;

      dispatch(register(body, "register")).then((res) => {
        if (res.success) {
          props.goToStep(2);
          localStorage.setItem("CurrentStep", 2);
        } else setError(res.errorMessage);
      });
    },
    validationSchema: signupSchema,
  });

  /******************* 
    @Purpose : Used for Handle Form Submit 
    @Parameter : {e}
    @Author : INIC
  *******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };
  /******************* 
    @Purpose : Used for Handle Social Login 
    @Parameter : {user}
    @Author : INIC
  *******************/
  const handleSocialLogin = (user) => {
    const body = { Barcode: user.accessToken, ExternalAuth: true };
    dispatch(register(body, "register")).then((_res) => {});
  };
  /******************* 
@purpose : Render Step 1 UI For Onboarding 
@Author  : INIC
******************/
  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <Container className="auth-main">
        <div className="auth-box">
          <div className="auth-heading">
            Create a Professional Partner Account
          </div>
          <GoogleLogin
            className="googlesignin-box"
            icon={false}
            clientId={GOOGLE_ID}
            buttonText="Sign up with Google"
            onSuccess={(user) => handleSocialLogin(user)}
            onFailure={(errors) => handleSocialLogin(errors)}
          />
          <div className="or-text">
            or
            {error && <div className="message-block">{error}</div>}
          </div>

          <div className="form-wrap">
            <Form onSubmit={handleSubmit} noValidate>
              <FormGroup className="form-group">
                <Select
                  classNamePrefix="custom-select"
                  placeholder="Select Title"
                  type="text"
                  id="title"
                  name="Title"
                  onChange={(e) => {
                    formik?.setFieldValue("Title", e.value);
                  }}
                  options={titleOptions}
                />
                {formik.errors.Title ? (
                  <div className="message-block">{formik.errors.Title}</div>
                ) : null}
              </FormGroup>
              <FormGroup className="form-group">
                <TextField
                  type="text"
                  placeholder="First name"
                  name="FirstName"
                  formik={formik}
                  id="firstName"
                  showUntoucherError
                />
              </FormGroup>
              <FormGroup className="form-group">
                <TextField
                  type="text"
                  placeholder="Last name"
                  name="LastName"
                  formik={formik}
                  id="lastName"
                  showUntoucherError
                />
              </FormGroup>
              <FormGroup
                className={`form-group input-ic-wrap  ${
                  formik.values.BirthDate ? "date-filled" : "date-empty"
                }`}
              >
                <DateTime
                  name="BirthDate"
                  placeholder="Date of birth"
                  value={formik.values.BirthDate}
                  locale="en-US"
                  dateFormat="DD/MM/YYYY"
                  closeOnSelect
                  timeFormat={false}
                  onChange={(e) => {
                    var dboData = moment(e).format("YYYY-MM-DD");
                    formik?.setFieldValue("BirthDate", dboData);
                  }}
                  inputProps={{
                    placeholder: "Date of birth",
                  }}
                />
                <i className="icon-calender" aria-hidden="true"></i>
                {formik.errors.BirthDate ? (
                  <div className="message-block">{formik.errors.BirthDate}</div>
                ) : null}
              </FormGroup>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type="email"
                  placeholder="Email address"
                  name="Email"
                  formik={formik}
                  id="email"
                  showUntoucherError
                />
                <i className="icon-envelope-alt" aria-hidden="true"></i>
              </FormGroup>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  name="Password"
                  formik={formik}
                  id="password"
                  showUntoucherError
                />
                <i
                  className={`${
                    passwordShown ? "icon-eye-slash" : "icon-eye"
                  } toggle-password`}
                  aria-hidden="true"
                  onClick={togglePasswordVisiblity}
                ></i>
              </FormGroup>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type={confirmPasswordShown ? "text" : "password"}
                  placeholder="Confirm password"
                  name="ConfirmPassword"
                  formik={formik}
                  id="confirmPassword"
                  showUntoucherError
                />
                <i
                  className={`${
                    confirmPasswordShown ? "icon-eye-slash" : "icon-eye"
                  } toggle-password`}
                  aria-hidden="true"
                  onClick={toggleConfirmPasswordVisiblity}
                ></i>
              </FormGroup>
              <FormGroup className="form-group">
                <div className="condition-text">
                  Set up your account details below. By continuing to the next
                  page you are{" "}
                  <Link to="/">agreeing to our terms &amp; conditions.</Link>
                </div>
              </FormGroup>
              <FormGroup className="form-group mb-0">
                <BtnMain
                  text="Create Account"
                  className={
                    !(formik.isValid && formik.dirty)
                      ? "btn black-btn w-100 disabled-btn"
                      : "btn black-btn w-100"
                  }
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                />
              </FormGroup>
            </Form>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
export default Step1;
