import React from "react";
import { Breadcrumb } from 'react-bootstrap';
import Layout from "../../../components/Layout/dashboard-layout/Layout";

function DnaQuestion(_props) {
 
 /******************* 
@Purpose : Used for render UI
@Parameter : {}
@Author : INIC
******************/

    return (
      <Layout >
        <div className="dashboard-inner-content ">    
          <Breadcrumb>
            <Breadcrumb.Item href="/assigned-tasks"  className="sm-text ">Assigned Tasks <span className="icon-arrow-right"></span> </Breadcrumb.Item>
            <Breadcrumb.Item href="/report/current-report" className="sm-text ">Sarah Jack <span className="icon-arrow-right"></span> </Breadcrumb.Item>
           
            <Breadcrumb.Item active className="sm-text">Prescription Consultation Results</Breadcrumb.Item>
          </Breadcrumb>
          <div className="btn-heading-wrap">
            <h3 className="h3 mb-4 black-text">
            Fitness DNA Questions </h3> 
            
          </div>
          <div className="tag-block lightGrey-tag-block normalText-tag-block mb-4">
            <div className="tag-tile b-400">Completed: June 23, 2021 at 21:34</div>
          </div> 
          <div className="profileInfo-wrap">
              <div className="profileInfo-block">
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">What are your goals?</div> 
                        <div className="sm-text grey-text user-textSubHeading"> Reduce stress</div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">List, in order of priority, three things you would like to do in order to improve your health and fitness. </div> 
                        <div className="sm-text grey-text user-textSubHeading">Diet, exercise, running </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">How often do you train a week? </div> 
                        <div className="sm-text grey-text user-textSubHeading"> 3</div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading"> How long do you spend in a typical cardio/endurance session?</div> 
                        <div className="sm-text grey-text user-textSubHeading"> Not applicable</div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">Are you on any special diet? </div> 
                        <div className="sm-text grey-text user-textSubHeading"> No</div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">How many servings of fresh fruit and vegetables do you consume a day? </div> 
                        <div className="sm-text grey-text user-textSubHeading">2 </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">How long on average do you take rests between sets (weightlifting)? </div> 
                        <div className="sm-text grey-text user-textSubHeading">1.5 minutes </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">On a scale of 1-10, how healthy do you think your daily diet is> </div> 
                        <div className="sm-text grey-text user-textSubHeading">7 </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">How many calories do you consume a day?</div> 
                        <div className="sm-text grey-text user-textSubHeading">1200</div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">Please list the types of diet or types of food that satisfy your appetite the most. </div> 
                        <div className="sm-text grey-text user-textSubHeading">Bread, fish, meat </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">What does your diet consist of post-training? </div> 
                        <div className="sm-text grey-text user-textSubHeading">Fish, beef, veggies </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">Do you have any hobbies or pastimes? </div> 
                        <div className="sm-text grey-text user-textSubHeading">Nope </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">What are the barriers keeping you from achieving your fitness and nutritional goals? </div> 
                        <div className="sm-text grey-text user-textSubHeading">Injury </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">What activities/exercise are you currently participating in?  </div> 
                        <div className="sm-text grey-text user-textSubHeading">Swimming </div> 
                      </div> 
                    </div> 
                 
                  </div>
                
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">How long do you spend in a typical resistance/weight training session?  </div> 
                        <div className="sm-text grey-text user-textSubHeading">10-30 minutes </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">Do you have any hobbies or pastimes?  </div> 
                        <div className="sm-text grey-text user-textSubHeading">Nope </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">Do you have any food allergies? </div> 
                        <div className="sm-text grey-text user-textSubHeading">No </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">How many meals do you have a day?  </div> 
                        <div className="sm-text grey-text user-textSubHeading">3 meals a day </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">What times in the day do you prefer to train and why?  </div> 
                        <div className="sm-text grey-text user-textSubHeading">Morning (7:30am-12pm) </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">On a scale of 1-10, how much pain do you experience following a cardio/endurance session?</div> 
                        <div className="sm-text grey-text user-textSubHeading">5 </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">On a scale of 1-10, how much pain do you experience following a weight/resistance session</div> 
                        <div className="sm-text grey-text user-textSubHeading">8 </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading"> Do you snack in between meals?</div> 
                        <div className="sm-text grey-text user-textSubHeading">No </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">What does your diet consist of pre-training </div> 
                        <div className="sm-text grey-text user-textSubHeading">Fish, bread </div> 
                      </div> 
                    </div> 
                 
                  </div>
                  <div className="profileInfo-tile">
                    <div className="user-block">
                      <div className="userImg-block ">
                      <div className="userIc info-light-bg">
                        <span className="icon-comment-question white-text"></span>  
                      </div>
                      
                      </div> 
                      <div className="user-text">
                        <div className="sm-text light-black-text b-700 user-textHeading">How many hours of sleep do you get every night on average? </div> 
                        <div className="sm-text grey-text user-textSubHeading">5-6 hours </div> 
                      </div> 
                    </div> 
                 
                  </div>
                </div>
              </div>  

            
        
        </div> 
       
            
      </Layout>
      );
}
export default DnaQuestion;
