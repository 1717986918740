import React from "react";

const validateCurrentStepAndProceed = (inst, redirectTo) => {
  let { currentStep, goToStep } = inst;
  console.log(redirectTo + "redirectTo");
  switch (currentStep) {
    case 1: {
      return goToStep(redirectTo);
    }
    case 2: {
      goToStep(redirectTo);
    }

    default:
      return false;
  }
};
/******************* 
@purpose : used for wizart Nav bar function
@Parameter : {} 
@Author : INIC
******************/
const Nav = (props) => {
  const { totalSteps, currentStep } = props;

  const dots = [];
  for (let i = 1; i <= totalSteps; i++) {
    const isActive = currentStep >= i;
    dots.push(
      <span
        key={`step-${i}`}
        className={`${"nav-line"} ${isActive ? "active" : ""}`}
        onClick={() => {
          currentStep !== i && validateCurrentStepAndProceed(props, i);
        }}
      >
        &bull;
      </span>
    );
  }

  return <div className={"nav-line-main"}>{dots}</div>;
};

export default Nav;
