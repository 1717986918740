import React from "react";

import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfilePhoto from "./../../../assets/images/temp-img/profile_photo.png";
import questionMark from "./../../../assets/images/color-icon/question-mark.svg";
import tShirt from "./../../../assets/images/color-icon/t-shirt.svg";
import plus from "./../../../assets/images/color-icon/plus.svg";
import stacked from "./../../../assets/images/color-icon/stacked.svg";
import Layout from "../../../components/Layout/dashboard-layout/Layout";
import BtnMain from "../../../components/buttons/btn_main";
function CurrentReport(_props) {
  /******************* 
@Purpose : Used for render UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <Layout>
      <div className="dashboard-inner-content pb-5">
        <Breadcrumb>
          <Breadcrumb.Item href="/assigned-tasks" className="sm-text ">
            Assigned Tasks <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="sm-text">
            Sarah Jack
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="current-report-wrap">
          <div className="currentReportProfile-block">
            <div className="user-block user-block-lg">
              <div className="userImg-block">
                <div
                  className="user-img"
                  style={{ backgroundImage: "url(" + ProfilePhoto + ")" }}
                ></div>
              </div>
              <div className="user-text">
                <div className="h3 mb-4 black-text b-700">Sarah Jack</div>
                <div className="tag-block blue-tag-block">
                  <div className="tag-tile">Female</div>
                  <div className="tag-tile">24 Years Old</div>
                  <div className="tag-tile">69 kg</div>
                  <div className="tag-tile">White</div>
                  <div className="tag-tile">United Kingdom</div>
                  <div className="tag-tile">Drinker</div>
                  <div className="tag-tile">Smoker</div>
                  <div className="tag-tile">Self-employed</div>
                  <div className="tag-tile">Alergies</div>
                  <div className="tag-tile">Asthma</div>
                </div>
              </div>
            </div>
            <div className="currentReportProfile-action d-flex">
              <Link
                to={"/report/general-questions"}
                className="btn gray-line-btn "
              >
                View{" "}
              </Link>
            </div>
          </div>
          <div className="profileInfo-wrap">
            <div className="grey-text xs-text b-700 profileInfo-heading">
              GENERAL INFORMATION{" "}
            </div>
            <div className="profileInfo-block black-text">
              <div className="profileInfo-tile">
                <div className="user-block">
                  <div className="userImg-block ">
                    <div className="userIc light-bg">
                      <img src={questionMark} alt={questionMark} />
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Lifestyle and Behaviour Quiz
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      Completed Dec 21, 2020
                    </div>
                  </div>
                </div>
                <div className="profileInfo-action d-flex">
                  <Link
                    to={"/report/general-questions"}
                    className="btn gray-line-btn "
                  >
                    View{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="profileInfo-wrap">
            <div className="grey-text xs-text b-700 profileInfo-heading">
              STEPS TO COMPLETE{" "}
            </div>
            <div className="profileInfo-block black-text">
              <div className="profileInfo-tile">
                <div className="user-block">
                  <div className="userImg-block ">
                    <div className="userIc light-bg">
                      <img src={questionMark} alt={questionMark} />
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Questions
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      Review the questions and commnet
                    </div>
                  </div>
                </div>
                <div className="profileInfo-action d-flex">
                  <BtnMain
                    text="Start"
                    className="btn gray-line-btn "
                    type="button"
                  />
                </div>
              </div>
              <div className="profileInfo-tile">
                <div className="user-block">
                  <div className="userImg-block ">
                    <div className="userIc light-bg">
                      <img src={tShirt} alt={tShirt} />
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Fitness DNA Report
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      Completed Jan 28, 2021
                    </div>
                  </div>
                </div>
                <div className="profileInfo-action d-flex">
                  <BtnMain
                    text="Start"
                    className="btn gray-line-btn "
                    type="button"
                  />
                </div>
              </div>
              <div className="profileInfo-tile">
                <div className="user-block">
                  <div className="userImg-block ">
                    <div className="userIc light-bg">
                      <img src={stacked} alt={stacked} />
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Set Goal
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      Completed Jan 28, 2021
                    </div>
                  </div>
                </div>
                <div className="profileInfo-action d-flex">
                  <BtnMain
                    text="Start"
                    className="btn gray-line-btn "
                    type="button"
                  />
                </div>
              </div>
              <div className="profileInfo-tile">
                <div className="user-block">
                  <div className="userImg-block ">
                    <div className="userIc light-bg">
                      <img src={plus} alt={plus} />
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Recomnded product{" "}
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      Recommend medicines and products to client
                    </div>
                  </div>
                </div>
                <div className="profileInfo-action d-flex">
                  <BtnMain
                    text="Start"
                    className="btn gray-line-btn "
                    type="button"
                  />
                </div>
              </div>
              <div className="profileInfo-tile">
                <div className="user-block">
                  <div className="userImg-block ">
                    <div className="userIc light-bg">
                      <img src={plus} alt={plus} />
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Recomnded product{" "}
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      Recommend medicines and products to client
                    </div>
                  </div>
                </div>
                <div className="profileInfo-action d-flex">
                  <BtnMain
                    text="Start"
                    className="btn gray-line-btn "
                    type="button"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default CurrentReport;
