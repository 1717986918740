import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/Layout/dashboard-layout/Layout";
import BtnMain from "../../../components/buttons/btn_main";
import MainModal from "../../../components/model/Model";
import AddNote from "../../../components/model/AddNote";
import { toggleModal } from "../../../store/Actions/Ui";
function Question(_props) {
  const dispatch = useDispatch();
  const model = useSelector((state) => state?.ui?.modals);

  const handleServiceClose = () => dispatch(toggleModal(false, "add_note"));

  /******************* 
@Purpose : Used for render UI
@Parameter : {}
@Author : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content">
        <Breadcrumb>
          <Breadcrumb.Item href="/assigned-tasks" className="sm-text ">
            Assigned Tasks <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/report/current-report" className="sm-text ">
            Sarah Jack <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>

          <Breadcrumb.Item active className="sm-text">
            Lifestyle and Behaviour Quiz
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="btn-heading-wrap">
          <h3 className="h3 mb-4 black-text">Lifestyle and Behaviour Quiz</h3>
          <BtnMain
            text="Add Note"
            className="btn btn-primary"
            onClick={() => dispatch(toggleModal(true, "add_note"))}
            type="submit"
          />
        </div>
        <div className="profileInfo-wrap">
          <div className="profileInfo-block">
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    What is your date of birth?
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    {" "}
                    7/14/1998
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    What is your gender?
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    {" "}
                    Female
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    What is your height?
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    {" "}
                    5 ft
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    What is your weight?
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    {" "}
                    69 kg
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    Please choose your ethnicity:
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    {" "}
                    White or not stated
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    Do / Did you smoke?
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    {" "}
                    Yes
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    Do you drink alcohol?
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    {" "}
                    Yes
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    Do you take vitamins, minerals or supplements?
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    {" "}
                    No
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    What is your Passport Number?
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    {" "}
                    N/A
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    How much water do you drink a day?
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    Over 10 glasses (approximately 2 liters) liter
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    How many hours a week do you work?{" "}
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    41 – 56 hours a week
                  </div>
                </div>
              </div>
            </div>
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc info-light-bg">
                    <span className="icon-comment-question white-text"></span>
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    What employment sector do you work in?
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    Self-employed
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dashboard-footer">
          <BtnMain
            text="Back"
            className="btn gray-line-btn me-4 fixWidth-btn"
            type="button"
          />
          <BtnMain
            text="Next"
            className="btn btn-primary fixWidth-btn"
            type="button"
          />
        </div>
      </div>
      <MainModal
        body={<AddNote />}
        ModalTitle="HDL Cholesterol"
        ModalDescription=""
        closeShow={true}
        show={model?.add_note}
        size={"Small"}
        className={"addNote-modal"}
        onHide={handleServiceClose}
        keyModal="add_note"
      />
    </Layout>
  );
}
export default Question;
