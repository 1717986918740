import React from "react";
import loader from "../../../assets/images/loader.svg";
import "./loader.scss";
function Loader(props) {
  /******************* 
@Purpose : Used for render Loader UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <div className="loader-wrap">
      <div className="loader-block">
        <img alt="Rightangled logo" id="logo" src={loader} width="150" />
        <div className="loader-text"> {props.text}</div>
      </div>
    </div>
  );
}

export default Loader;
