import React from "react";
import { Link } from "react-router-dom";
import logo from "./../../assets/images/blueLogo.svg";

function Header(_props) {
  /******************* 
@Purpose : Used for render HTML/Components
@Parameter : {}
@Author : INIC
******************/
  return (
    <header className="app-header">
      <div className="container">
        <nav className="nav-extended">
          <Link to="/">
            <img alt="Rightangled logo" id="logo" src={logo} width="150" />
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
