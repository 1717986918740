import React , {Suspense}from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import {  get_cookie } from "./utils/Functions";
import {BrowserRouter as Router,Route,Switch,Redirect,} from "react-router-dom";
// Authentication pages
// 404 page
import FourZeroFour from "./pages/FourZeroFour/FourZeroFour";
// Offline page
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// components
import Welcome from "./pages/Welcome";
//notification library
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// customized design intigration

import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";

import './assets/scss/style.scss';
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/register/Register";
import ResetPassword from "./pages/Auth/ResetPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import StripePayment from "./pages/Auth/StripePayment";
import LastStep from "./pages/Auth/LastStep";
import ClientProfile from "./pages/client-profile/ClientProfile";
import PasswordReset from "./pages/client-profile/PasswordReset";
import DashBoard from "./pages/dashboard/DashBoard";
import AssignedTasks from "./pages/assigned-tasks/AssignedTasks";
import CurrentReport from "./pages/task-steps/current-report/CurrentReport";
import PrescriberCurrentReport from "./pages/presciber-task-steps/current-report/CurrentReport";
import UserProfile from "./pages/presciber-task-steps/current-report/UserProfile";
import GeneralQuestion from "./pages/task-steps/report-steps/steps-pages/GeneralQuestion";
import Question from "./pages/task-steps/question/Question";
import PrescriptionResults from "./pages/presciber-task-steps/prescription-results/PrescriptionResults";
import PrescriberQuestion from "./pages/presciber-task-steps/dna-question/Question";
import DnaQuestion from "./pages/presciber-task-steps/dna-question/Question";

import Thanks from "./pages/thanks/Thanks";
import PrescriptionDetails from "./pages/presciber-task-steps/prescription-details/PrescriptionDetails";
/******************* 
@Purpose : This page is default page for our project
@Parameter : {}
@Author : INIC
******************/
function App(props) {
  /******************* 
  @Purpose : Used for token authorization
  @Parameter : {}
  @Author : INIC
  ******************/
  const Authorization = () => {
    return get_cookie("accessToken") ? true : false;
  };
  /******************* 
  @Purpose : Used for private routing
  @Parameter : {Component1, ...rest}
  @Author : INIC
  ******************/
  const PrivateRoute = ({ component: Component1, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? <Component1 {...props} /> : <Redirect to="/" />
      }
    />
  );
  /******************* 
  @Purpose : Used for check user is already login or not
  @Parameter : {Component2, ...rest }
  @Author : INIC
  ******************/
  const LoginCheckRoute = ({ component: Component2, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component2 {...props}  />
        )
      }
    />
  );
  /******************* 
  @Purpose : Used for check reset password
  @Parameter : {Component3, ...rest}
  @Author : INIC
  ******************/
  const ResetPasswordCheckRoute = ({ component: Component3, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component3 {...props} />
        )
      }
    />
  );
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
       
      
      <PersistGate loading={null} persistor={persistor}>
            <Router>
              <div style={{ textAlign: "center" }}>
                <ToastContainer />
              </div>
              <Switch>
                <LoginCheckRoute exact path="/" component={Welcome} welcomePage={true} />
                <LoginCheckRoute exact path="/login" component={Login} />
                <LoginCheckRoute exact path="/register" component={Register}  />
                <ResetPasswordCheckRoute exact path="/reset-password" component={ResetPassword} />
                <LoginCheckRoute exact path="/forgot-password" component={ForgotPassword} />
                <LoginCheckRoute exact path="/last-step" component={LastStep} />
                <LoginCheckRoute exact path="/stripe" component={StripePayment} />
                <PrivateRoute exact path="/client-profile" component={ClientProfile} />
                <PrivateRoute exact path="/change-password" component={PasswordReset} />
                <PrivateRoute exact path="/dashboard" component={DashBoard} />
                <PrivateRoute exact path="/assigned-tasks" component={AssignedTasks} />
                <PrivateRoute exact path="/report/current-report" component={CurrentReport} />
                <PrivateRoute exact path="/report/general-questions" component={Question} />
                <PrivateRoute exact path="/report/steps/questions" component={GeneralQuestion} />
                <PrivateRoute exact path="/prescriber/current-report" component={PrescriberCurrentReport} />
                <PrivateRoute exact path="/prescriber/user-profile" component={UserProfile} />
                <PrivateRoute exact path="/prescriber/prescription-results" component={PrescriptionResults} />
                <PrivateRoute exact path="/prescriber/general-questions" component={PrescriberQuestion} />
                <PrivateRoute exact path="/prescriber/dna-question" component={DnaQuestion} />
                <PrivateRoute exact path="/prescriber/prescription-details" component={PrescriptionDetails} />
                <PrivateRoute exact path="/prescriber/thanks" component={Thanks} />
                <Route path="/*" component={FourZeroFour} />
              </Switch>
            </Router>
            </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;

