import React, { Fragment, useState, useEffect } from "react";
import TextField from "../inputs/textfield";
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { step2Schema } from "../../utils/schema";
import { getProfessionalList, getProfessionalData } from "../../store/Actions";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout/Layout";
import Select from "react-select";
import Loader from "../Layout/loader/Loader";
import BtnMain from "../buttons/btn_main";
import UploadFile from "../inputs/UploadFile";
function Step2(props) {
  const [previewBanner, SetpreviewBanner] = useState("");
  const isLoading = useSelector((state) => state?.ui?.loaders?.step2);
  const userInfo = useSelector((state) => state?.user?.userInfo?.data?.user);
  const professionalInfo = useSelector(
    (state) => state?.user?.professionListInfo
  );
  const [error, setError] = useState("");
  const [reviewTest, setReviewTest] = useState("");
  var length = Object.keys(professionalInfo).length;
  const dispatch = useDispatch();

  /*** 
    @purpose :hooks used for api call
    @Parameter : {} 
    @Author : INIC
    **/
  useEffect(() => {
    const body = "";
    dispatch(getProfessionalList(body));
  }, []);

  const formik = useFormik({
    initialValues: {
      ProfessionId: "",
      GMCReferenceNumber: "",
    },
    onSubmit(values) {
      const formData = new FormData();

      formData.append("isHaveFacility", false);
      var heartDNA = false;
      var fitnessDNA = false;
      var covid = false;
      var pomStatus = false;

      if (reviewTest == 70 || reviewTest == 80) {
        pomStatus = true;
      } else if (reviewTest == 20 || reviewTest == 30) {
        fitnessDNA = true;
      } else if (reviewTest == 10 || reviewTest == 40) {
        heartDNA = true;
      } else if (reviewTest == 50) {
        fitnessDNA = true;
        heartDNA = true;
      } else if (reviewTest == 60) {
        covid = true;
      } else {
        heartDNA = false;
        fitnessDNA = false;
        covid = false;
        pomStatus = false;
      }

      formData.append("includeHeartDNATest", heartDNA);
      formData.append("includeFitnessDNATest", fitnessDNA);
      formData.append("includeCovid19Test", covid);
      formData.append("includePOM", pomStatus);
      formData.append("UserId", userInfo.id);
      formData.append("ProfessionId", values.ProfessionId);
      formData.append("GMCReferenceNumber", values.GMCReferenceNumber);
      formData.append("file", values.image);
      dispatch(getProfessionalData(formData, "step2")).then((res) => {
        if (!res.success) {
          props.goToStep(2);
          localStorage.setItem("CurrentStep", 3);
        } else setError(res.errorMessage);
        props.goToStep(3);
      });
    },
    validationSchema: step2Schema,
  });

  /******************* 
@purpose : used for Profesional List Array
@Author : INIC
******************/
  const getrows = [];

  if (length > 0) {
    var i = 1;
    Object.keys(professionalInfo).forEach(function (key) {
      if (i < length) {
        getrows.push({
          value: professionalInfo[key].partnerProfessionId,
          label: professionalInfo[key].partnerProfessionName,
        });
      }
      i++;
    });
  }
  const Profession = getrows;

  /******************* 
@purpose : used for Form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };
  /******************* 
    @purpose : used for drop a video
    @Parameter : {file} 
    @Author : INIC
    ******************/
  const onDrop = (files) => {
    if (files) {
      SetpreviewBanner(global.URL.createObjectURL(files[0]));
      formik?.setFieldValue("image", files[0]);
    }
  };
  /******************* 
@purpose : Render Step 2 UI For Onboarding 
@Author  : INIC
******************/
  var firstName = "";
  if (userInfo) {
    firstName = userInfo.firstName;
  }
  return (
    <Layout>
      <div className="step-main-wrap">
        <Form onSubmit={handleSubmit} noValidate>
          <div className="progress-baar">
            <div className="progress-baar-value" style={{ width: "25%" }}></div>
          </div>
          {isLoading && <Loader text="Loading" />}
          <div className="createAcount-wrap">
            <div className="step-wrap">
              <div className="step-heading">Hello {firstName}. &#128075;</div>
              <div className="step-heading">
                What type of professional are you?
              </div>
              <div className="step-idea-box">
                <div className="step-idea-ic">
                  <i className="icon-lightbulb-alt"></i>
                </div>
                <div className="step-idea-text">
                  This will help us understand what type of DNA or Blood tests
                  you will be able to review for clients, and whether you will
                  be able to issue prescriptions for patients.
                </div>
              </div>
              {error && <div className="message-block">{error}</div>}
              <div className="step-form-wrap">
                <FormGroup className="form-group fist-step-select">
                  <Select
                    classNamePrefix="custom-select"
                    placeholder="Select Title"
                    type="text"
                    id="ProfessionId"
                    name="ProfessionId"
                    onChange={(e) => {
                      setReviewTest(e.value);

                      formik?.setFieldValue("ProfessionId", e.value);
                    }}
                    options={Profession}
                  />
                  {formik.errors.ProfessionId ? (
                    <div className="message-block">
                      {formik.errors.ProfessionId}
                    </div>
                  ) : null}
                </FormGroup>
                <FormGroup className="form-group ">
                  <div className="stepform-heading">
                    Please add registration number and certificates{" "}
                  </div>
                  <TextField
                    type="text"
                    placeholder="Enter Registration No"
                    name="GMCReferenceNumber"
                    formik={formik}
                    id="Registration"
                    showUntoucherError
                  />
                </FormGroup>
                <UploadFile onDrop={onDrop} accept="image/*">
                  <div className="dropzon-main-wrap">
                    {previewBanner ? (
                      <div className="preview-wrap">
                        <div className="preview-img">
                          <span
                            className="preview-ic icon-multiply"
                            onClick={(e) => {
                              SetpreviewBanner("");
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          ></span>
                          <img src={previewBanner} alt="icon" />
                        </div>
                      </div>
                    ) : (
                      <Fragment>
                        <div className="dropzon-wrap">
                          <span className="icon-upload"></span>
                          <div className="dropzone-heading lg-text">
                            Upload Certifications{" "}
                          </div>
                          <div className="dropzone-text xs-text">
                            Uploading your certificate will speed up our
                            verification process and have you onboarded faster.
                          </div>
                          <svg viewBox="0 0 300 100" preserveAspectRatio="none">
                            <path
                              d="M0,0 300,0 300,100 0,100z"
                              vectorEffect="non-scaling-stroke"
                            />
                          </svg>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </UploadFile>
                {formik.errors.image ? (
                  <div className="message-block">{formik.errors.image}</div>
                ) : null}
              </div>
              <div className="stepTag-wrap">
                <div className="stepform-heading">
                  You will be able to review these types of tests.
                </div>

                <div className="tag-block">
                  {reviewTest == 70 || reviewTest == 80 ? (
                    <div className="tag-tile">POM</div>
                  ) : reviewTest == 20 || reviewTest == 30 ? (
                    <div className="tag-tile">Fitness DNA Test</div>
                  ) : reviewTest == 10 || reviewTest == 40 ? (
                    <div className="tag-tile">Heart DNA Test</div>
                  ) : reviewTest == 50 ? (
                    <>
                      <div className="tag-tile">Heart DNA Test</div>
                      <div className="tag-tile">Fitness DNA Test</div>
                    </>
                  ) : reviewTest == 60 ? (
                    <div className="tag-tile">Covid-19 Test</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="step-footer">
            <div className="step-footer-cotainer">
              <BtnMain
                text="Back"
                className="btn gray-line-btn me-4"
                onClick={() => props.goToStep(1)}
                type="button"
              />
              <BtnMain text="Next" className="btn btn-primary" type="submit" />
            </div>
          </div>
        </Form>
      </div>
    </Layout>
  );
}
export default Step2;
