import React, { useEffect, useState } from "react";
import { toggleModal } from "../../store/Actions/Ui";
import BtnMain from "../buttons/btn_main";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceAgreementList,
  sendServiceAgreementData,
  setLastCompletedStep,
} from "../../store/Actions";
import Loader from "../Layout/loader/Loader";
function ServiceAgreement() {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.user?.userInfo?.data?.user);
  const isLoading = useSelector((state) => state?.ui?.loaders?.agreement);
  const agreementLoading = useSelector(
    (state) => state?.ui?.loaders?.service_agrement
  );
  const serviceAggrement = useSelector(
    (state) => state?.user?.getServiceAgreement
  );
  useEffect(() => {
    dispatch(getServiceAgreementList("service_agrement"));
  }, []);

  /******************* 
@purpose : used for Accept Button Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleClick = (e) => {
    e.preventDefault();
    const body = { Id: userInfo.id, ServiceRulesAgreed: true };
    dispatch(sendServiceAgreementData(body, "agreement")).then((res) => {
      if (res.success) {
        dispatch(setLastCompletedStep(true, "serviceAgreement"));
        dispatch(toggleModal(false, "service_agreement"));
      } else setError(res.errorMessage);
    });
  };

  /******************* 
    @purpose : Service Agreement Modal Component
    @Parameter : {} 
    @Author : INIC
    ******  ************/
  return (
    <>
      {(isLoading || agreementLoading) && <Loader text="Loading" />}
      {error && <div className="message-block">{error}</div>}
      <div
        className="modal-body-inner service-agreement-body"
        dangerouslySetInnerHTML={{ __html: serviceAggrement?.data }}
      ></div>
      <div className="modal-footer justify-content-center">
        <BtnMain
          text="Decline"
          className="btn  gray-line-btn m-0 me-2"
          onClick={() => dispatch(toggleModal(false, "service_agreement"))}
          type="button"
        />
        <BtnMain
          text="Accept"
          className="btn btn-primary m-0 ms-2"
          onClick={handleClick}
          type="button"
        />
      </div>
    </>
  );
}
export default ServiceAgreement;
