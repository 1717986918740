import React from "react";
import Step1 from "../../../components/register/Step1";
import Step2 from "../../../components/register/Step2";
import Step3 from "../../../components/register/Step3";
import Step4 from "../../../components/register/Step4";
import Step5 from "../../../components/register/Step5";
import StepWizard from "react-step-wizard";
import Nav from "../../../components/nav/index";

function Register() {
  var getCurrentStep = localStorage.getItem("CurrentStep");
  var CurrentStep = 1;
  if (getCurrentStep !== "") {
    CurrentStep = getCurrentStep;
  }
  const onStepChange = (stats) => {
    return stats;
  };
  /******************* 
@Purpose : Used for render UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <div>
      <StepWizard
        onStepChange={onStepChange}
        initialStep={CurrentStep}
        nav={<Nav />}
      >
        <Step1 />
        <Step2 />
        <Step3 />
        <Step4 />
        <Step5 />
      </StepWizard>
    </div>
  );
}
export default Register;
