import React, { useState } from "react";
import Layout from "../Layout/Layout";
import Loader from "../Layout/loader/Loader";
import BtnMain from "../buttons/btn_main";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { sendProfessionalAddress } from "../../store/Actions";
import { Link } from "react-router-dom";
import { Form, FormGroup } from "react-bootstrap";
import TextField from "../inputs/textfield";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GOOGLE_KEY } from "../../config/index";
import { step4Schema } from "../../utils/schema";
export default function Step4(props) {
  const [value, setValue] = useState(null);
  const [error, setError] = useState("");
  const isLoading = useSelector((state) => state?.ui?.loaders?.step4);
  const userInfoUpdated = useSelector(
    (state) => state?.user?.userInfoUpdated?.data?.data
  );
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      Address1: "",
      Address2: "",
      City: "",
      Country: "",
    },
    onSubmit(values) {
      const body = values;
      var data = {
        clinicAddress: body,
        UserId: userInfoUpdated.userId,
        id: userInfoUpdated.id,
      };
      dispatch(sendProfessionalAddress(data, "step4")).then((res) => {
        if (res.success) {
          props.goToStep(5);
          localStorage.setItem("CurrentStep", 5);
        } else setError(res.errorMessage);
      });
    },
    validationSchema: step4Schema,
  });
  /******************* 
@purpose : used for Form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();

    formik.submitForm();
  };

  /******************* 
@purpose : used for Google Places Handle
@Parameter : {value} 
@Author : INIC
******************/
  const handleAutocomplete = (value) => {
    setValue();
    formik.setFieldValue("Address1", value["value"]["description"]);
    var countryVal = value["value"]["terms"].slice(-1)[0];
    var cityVal = value["value"]["terms"].slice(-3)[0];
    formik.setFieldValue("City", cityVal["value"]);
    formik.setFieldValue("Country", countryVal["value"]);
  };
  /******************* 
@purpose : Render Step 4 UI For Onboarding 
@Author  : INIC
******************/
  return (
    <Layout>
      <div className="step-main-wrap">
        <Form onSubmit={handleSubmit} noValidate>
          <div className="progress-baar">
            <div className="progress-baar-value" style={{ width: "75%" }}></div>
          </div>
          {isLoading && <Loader text="Loading" />}
          <div className="createAcount-wrap">
            <Link to="/" className="step-skip-btn lg-text">
              Skip this step, I am just taking a look
            </Link>
            <div className="step-wrap">
              <div className="step-heading">What is your address?</div>
              <div className="step-idea-box">
                <div className="step-idea-ic">
                  <i className="icon-lightbulb-alt"></i>
                </div>
                <div className="step-idea-text">
                  We use your address to ship DNA and Blood tests Kits
                </div>
              </div>
              {error && <div className="message-block">{error}</div>}
              <div className="step-form-wrap">
                <FormGroup className="form-group">
                  <GooglePlacesAutocomplete
                    apiKey={GOOGLE_KEY}
                    selectProps={{
                      value,
                      onChange: (value) => handleAutocomplete(value),
                    }}
                    name="Address1"
                    value={value}
                  />
                  {formik.errors.Address1 ? (
                    <div className="message-block">
                      {formik.errors.Address1}
                    </div>
                  ) : null}
                </FormGroup>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Address line 2"
                    name="Address2"
                    formik={formik}
                    id="Address2"
                    showUntoucherError
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="City"
                    name="City"
                    formik={formik}
                    id="City"
                    showUntoucherError
                  />
                </FormGroup>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Country"
                    name="Country"
                    formik={formik}
                    id="Country"
                    showUntoucherError
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="step-footer">
            <div className="step-footer-cotainer">
              <BtnMain
                text="Back"
                className="btn gray-line-btn me-4"
                onClick={() => props.goToStep(3)}
                type="button"
              />
              <BtnMain text="Next" className="btn btn-primary" type="submit" />
            </div>
          </div>
        </Form>
      </div>
    </Layout>
  );
}
