/* istanbul ignore file */
/*******************
@purpose : Redux Action Types
@Author : INIC
******************/

// UI reducer types
export const SET_UI_KEY = 'SET_UI_KEY';
export const SHOW_ALERT = 'SHOW_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
// Auth reducer types
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const LOGOUT_USER = 'LOGOUT_USER';

// User reducer types
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_INFO_UPDATED = 'SET_USER_INFO_UPDATED';
// Onboarding reducer types
export const SET_PROFESSIONAL_INFO="SET_PROFESSIONAL_INFO";
export const SET_MOTIVATIONAL_INFO="SET_MOTIVATIONAL_INFO";
// LAST_COMPLETED_STEP reducer types
export const SET_LAST_COMPLETED_STEP="SET_LAST_COMPLETED_STEP";
// SET_COUNTRY_CODE_INFO reducer types
export const SET_COUNTRY_CODE_INFO="SET_COUNTRY_CODE_INFO";
// SET_SERVICE_AGREEMENT_INFO reducer types
export const SET_SERVICE_AGREEMENT_INFO="SET_SERVICE_AGREEMENT_INFO";
