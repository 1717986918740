import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toggleModal } from "../../store/Actions/Ui";

function MainModal(props) {
  const dispatch = useDispatch();
  const {
    className,
    show,
    size,
    body,
    keyModal,
    isTitle,
    closeIcon = props.closeShow ? true : false,
    ModalTitle,
    ModalDescription,
    discribeCode,
    keepOpen = false,
    onClose = () => {
      return;
    },
    staticModal = false,
  } = props;

  /******************* 
    @purpose : Callback func to close modal based on key passed to the redux store
    @Parameter : {} 
    @Author : INIC
    ******************/
  const closeModal = () => {
    if (!keepOpen) {
      dispatch(toggleModal(false, keyModal));
      onClose();
    }
  };
  /******************* 
    @purpose : Render Modal Component
    @Parameter : {} 
    @Author : INIC
    ******  ************/
  return (
    <div data-testid="modal">
      <Modal
        className={className}
        show={show}
        onHide={closeModal}
        size={size}
        centered
        backdrop={staticModal ? "static" : true}
      >
        {isTitle ? <h2>{isTitle}</h2> : ""}
        <Modal.Header
          closeButton={closeIcon}
          onHide={closeModal}
          className={`${props.closeShow ? "modalWith-close" : ""}`}
        >
          <Modal.Title
            className={` ${props.closeShow ? "w-100" : "text-center w-100"}`}
          >
            {ModalTitle}
            {ModalDescription}
            {discribeCode ? (
              <p>
                <span className="black-text">{discribeCode}</span>
              </p>
            ) : (
              ""
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>{body}</Modal.Body>
      </Modal>
    </div>
  );
}
export default MainModal;
