import React from "react";

import { Breadcrumb } from "react-bootstrap";

import Layout from "../../../components/Layout/dashboard-layout/Layout";

function PrescriptionResults(props) {
  /******************* 
@purpose : Render For Prescription Results 
@Author  : INIC
******************/
  return (
    <Layout>
      <div className="dashboard-inner-content">
        <Breadcrumb>
          <Breadcrumb.Item href="/assigned-tasks" className="sm-text ">
            Assigned Tasks <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            href="/prescriber/current-report"
            className="sm-text "
          >
            Sarah Jack <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>

          <Breadcrumb.Item active className="sm-text">
            Prescription Consultation Results
          </Breadcrumb.Item>
        </Breadcrumb>
        <h3 className="h3 mb-4 black-text">
          Prescription Consultation Results
        </h3>
        <div className="tag-block lightGrey-tag-block normalText-tag-block mb-4">
          <div className="tag-tile b-400">
            Completed: June 23, 2021 at 21:34
          </div>
        </div>
        <div className="resultsQuestion-block">
          <div className="resultsQuestion-tile">
            <div className="sm-text light-black-text b-700 resultsQuestiontextHeading">
              Sunt rerum doloribus?
            </div>
            <div className="sm-text grey-text resultsQuestiontextSubHeading">
              Incidunt et fuga quaerat qui possimus. Provident et dolorem cum
              error ut et blanditiis officiis laborum.
            </div>
          </div>
          <div className="resultsQuestion-tile">
            <div className="sm-text light-black-text b-700 resultsQuestiontextHeading">
              Praesentium magni necessitatibus?
            </div>
            <div className="sm-text grey-text resultsQuestiontextSubHeading">
              Minima accusamus qui debitis illo quia molestiae.
            </div>
          </div>
          <div className="resultsQuestion-tile">
            <div className="sm-text light-black-text b-700 resultsQuestiontextHeading">
              Debitis perspiciatis est voluptas ratione nostrum.
            </div>
            <div className="sm-text grey-text resultsQuestiontextSubHeading">
              Laborum ullam qui quo et. Nobis saepe error quo sunt accusantium
              sunt omnis at. Unde voluptatem quasi fugiat.
            </div>
          </div>
          <div className="resultsQuestion-tile">
            <div className="sm-text light-black-text b-700 resultsQuestiontextHeading">
              Illo et deleniti non atque ea sed est sint officia.
            </div>
            <div className="sm-text grey-text resultsQuestiontextSubHeading">
              No
            </div>
          </div>
          <div className="resultsQuestion-tile">
            <div className="sm-text light-black-text b-700 resultsQuestiontextHeading">
              Reiciendis fugiat velit.
            </div>
            <div className="sm-text grey-text resultsQuestiontextSubHeading">
              Yes
            </div>
          </div>
          <div className="resultsQuestion-tile">
            <div className="sm-text light-black-text b-700 resultsQuestiontextHeading">
              Assumenda cumque est reprehenderit molestiae quo.
            </div>
            <div className="sm-text grey-text resultsQuestiontextSubHeading">
              Yes
            </div>
          </div>
          <div className="resultsQuestion-tile">
            <div className="sm-text light-black-text b-700 resultsQuestiontextHeading">
              In est voluptas et sunt. Ea natus quia et vel vitae quia. Sunt
              quaerat suscipit dolor voluptates qui esse non corrupti sed.
            </div>
            <div className="sm-text grey-text resultsQuestiontextSubHeading">
              No
            </div>
          </div>
          <div className="resultsQuestion-tile">
            <div className="sm-text light-black-text b-700 resultsQuestiontextHeading">
              Non recusandae exercitationem delectus et libero.
            </div>
            <div className="sm-text grey-text resultsQuestiontextSubHeading">
              No
            </div>
          </div>
          <div className="resultsQuestion-tile">
            <div className="sm-text light-black-text b-700 resultsQuestiontextHeading">
              Delectus nulla sed consequatur quasi.
            </div>
            <div className="sm-text grey-text resultsQuestiontextSubHeading">
              Eveniet amet ea ea beatae suscipit aspernatur odit cupiditate
              consequuntur.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default PrescriptionResults;
