import React from "react";
import { setLastCompletedStep, toggleModal } from "../../store/Actions";
import BtnMain from "../buttons/btn_main";
import { useDispatch } from "react-redux";
function BookCall() {
  const dispatch = useDispatch();
  const handleBooking = () => {
    dispatch(setLastCompletedStep(true, "bookCall"));
    dispatch(toggleModal(false, "bookcall"));
  };
  /******************* 
    @purpose : Book A Call Modal Component
    @Parameter : {} 
    @Author : INIC
    ******  ************/

  return (
    <>
      <div className="modal-body-inner">
        <iframe
          id="iframe"
          src="https://meetings.hubspot.com/a28/meeting-with-marko?embed=true"
          width="100%"
          height="500px"
        ></iframe>
      </div>
      <div className="modal-footer justify-content-center">
        <BtnMain
          text="Booked"
          className="btn btn-primary m-0 ms-2"
          onClick={handleBooking}
          type="button"
        />
      </div>
    </>
  );
}
export default BookCall;
