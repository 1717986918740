import * as types from "../Actions/Types";
import { get_cookie, isValidValue } from "../../utils"; //Utility functions
/******************* 
@purpose : Intital user reducer data
@Author : INIC
******************/
const initialState = {
  token: get_cookie && isValidValue(get_cookie("token")),
  refresh_token: get_cookie && get_cookie("refresh_token"),
  userInfo: {},
  userInfoUpdated: {},
  professionListInfo: {},
  CountryCodeListInfo: {},
  motivationPartnerListInfo: {},
  completedStep: {
    professionalDetails: false,
    serviceAgreement: false,
    sign: false,
    bookCall: false,
    stripe: false,
  },
  getServiceAgreement: {},
};
/******************* 
@purpose : ui reducer
@Parameter : {user_ini_data, action}
@Author : INIC
******************/
const user = (user_ini_data = initialState, action = {}) => {
  switch (action.type) {
    // Set user authorized token
    case types.SET_TOKEN:
      return Object.assign({}, user_ini_data, {
        token: action.tokens.access,
        refresh_token: action.tokens.refresh,
      });
    // Remove user authorized token
    case types.LOGOUT_USER:
      return Object.assign({}, user_ini_data, {
        token: false,
        refresh_token: false,
      });
    // Set login user information
    case types.SET_USER_INFO:
      return Object.assign({}, user_ini_data, {
        userInfo: { ...user_ini_data.data, ...action.data },
      });
    // Set login user  updated information after Step 1
    case types.SET_USER_INFO_UPDATED:
      return Object.assign({}, user_ini_data, {
        userInfoUpdated: { ...user_ini_data.data, ...action.data },
      });
    // Set Professional information
    case types.SET_PROFESSIONAL_INFO:
      return Object.assign({}, user_ini_data, {
        professionListInfo: { ...user_ini_data.data, ...action.data },
      });
    // Get Country Code information
    case types.SET_COUNTRY_CODE_INFO:
      return Object.assign({}, user_ini_data, {
        CountryCodeListInfo: { ...user_ini_data.data, ...action.data },
      });

    // Set Motivation info onboarding  information
    case types.SET_MOTIVATIONAL_INFO:
      return Object.assign({}, user_ini_data, {
        motivationPartnerListInfo: { ...user_ini_data.data, ...action.data },
      });
    // Set Last Completed Step
    case types.SET_LAST_COMPLETED_STEP:
      let completedStep = Object.assign({}, user_ini_data.completedStep);
      completedStep[action.key] = action.value;
      return Object.assign({}, user_ini_data, {
        completedStep,
      });
    // Set Service agreement information
    case types.SET_SERVICE_AGREEMENT_INFO:
      return Object.assign({}, user_ini_data, {
        getServiceAgreement: { ...user_ini_data.data, ...action.data },
      });
    default:
      return user_ini_data;
  }
};

export default user;
