import React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import Layout from "../../../components/Layout/dashboard-layout/Layout";
import BtnMain from "../../../components/buttons/btn_main";
function PrescriptionDetails(props) {
  /******************* 
@purpose : Render For Prescription Details 
@Author  : INIC
******************/
  return (
    <Layout>
      <div className="dashboard-inner-content">
        <Breadcrumb>
          <Breadcrumb.Item href="/assigned-tasks" className="sm-text ">
            Assigned Tasks <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            href="/prescriber/current-report"
            className="sm-text "
          >
            Sarah Jack <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>

          <Breadcrumb.Item active className="sm-text">
            Prescription
          </Breadcrumb.Item>
        </Breadcrumb>
        <h3 className="h3 mb-4 black-text">Prescription</h3>
        <div className="profileInfo-block black-text pb-3">
          <Row>
            <Col md={6} lg={3} className="mb-4">
              <div className="profileInfo-tile full-height  p-3">
                <div className="user-block pe-0">
                  <div className="userImg-block ">
                    <div className="userIc success-light-bg">
                      <span className="icon-notes white-text"></span>
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      DOB
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      23/01/2000
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={3} className="mb-4">
              <div className="profileInfo-tile full-height  p-3">
                <div className="user-block pe-0">
                  <div className="userImg-block ">
                    <div className="userIc success-light-bg">
                      <span className="icon-smile white-text"></span>
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Age
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      21
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} className="mb-4">
              <div className="profileInfo-tile full-height  p-3">
                <div className="user-block pe-0">
                  <div className="userImg-block ">
                    <div className="userIc success-light-bg">
                      <span className="icon-user-profile white-text"></span>
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Patient’s name:
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      Mr S Smith
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} className="mb-4">
              <div className="profileInfo-tile full-height  p-3">
                <div className="user-block pe-0">
                  <div className="userImg-block ">
                    <div className="userIc success-light-bg">
                      <span className="icon-location-pin white-text"></span>
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Patients Address:
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      12 Oxford Street, London, W1 2qe
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12}></Col>
            <Col md={6} lg={6} className="mb-4">
              <div className="profileInfo-tile full-height  p-3">
                <div className="user-block pe-0 align-items-start">
                  <div className="userImg-block ">
                    <div className="userIc warning-light-bg">
                      <span className="icon-pill white-text"></span>
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Rx
                    </div>
                    <div className="sm-text grey-text">16/11/2021</div>
                    <div className="sm-text grey-text">Quantity: 21</div>
                    <div className="sm-text grey-text">
                      Name of the drug:{" "}
                      <span className="primary-text">Amoxicllin</span>
                    </div>
                    <div className="sm-text grey-text">
                      Formulation:{" "}
                      <span className="primary-text">Capsules</span>
                    </div>
                    <div className="sm-text grey-text">
                      Strength: <span className="primary-text">500mg</span>
                    </div>
                    <div className="sm-text grey-text">
                      Dose:{" "}
                      <span className="primary-text">
                        One Three Times a Day
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} className="mb-4">
              <div className="profileInfo-tile full-height  p-3">
                <div className="user-block pe-0 align-items-start">
                  <div className="userImg-block ">
                    <div className="userIc warning-light-bg">
                      <span className="icon-pill white-text"></span>
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Rx
                    </div>
                    <div className="sm-text grey-text">16/11/2021</div>
                    <div className="sm-text grey-text">Quantity: 10</div>
                    <div className="sm-text grey-text">
                      Name of the drug:{" "}
                      <span className="primary-text">Percitamal</span>
                    </div>
                    <div className="sm-text grey-text">
                      Formulation: <span className="primary-text">Teblet</span>
                    </div>
                    <div className="sm-text grey-text">
                      Strength: <span className="primary-text">650 mg</span>
                    </div>
                    <div className="sm-text grey-text">
                      Dose:{" "}
                      <span className="primary-text">One Times a Day</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} className="mb-4">
              <div className="profileInfo-tile full-height  p-3">
                <div className="user-block pe-0 align-items-start">
                  <div className="userImg-block ">
                    <div className="userIc warning-light-bg">
                      <span className="icon-post-stamp white-text"></span>
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Signature
                    </div>
                    <div className="sm-text primary-text">
                      K Smith (electronic signature)
                    </div>
                    <div className="sm-text grey-text">
                      (Prescriber’s name, address and registration)
                    </div>
                    <div className="sm-text primary-text">Dr K Smith</div>
                    <div className="sm-text primary-text">
                      GMC: 1234567, or GPhC: 123456
                    </div>
                    <div className="sm-text grey-text">Rightangled</div>
                    <div className="sm-text grey-text">
                      32 Galena Road Hammersmith W6 0LT
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="dashboard-footer">
            <BtnMain
              text="Edit"
              className="btn gray-line-btn me-4 fixWidth-btn"
              type="button"
            />
            <BtnMain
              text="Sign Prescription"
              className="btn btn-success "
              type="button"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default PrescriptionDetails;
