/******************* 
@Purpose : Used for environment configuration
@Parameter : {API_URL, PORT}
@Author : INIC
******************/
module.exports = {
  API_URL: process.env.REACT_APP_API_URL,
    PORT: process.env.REACT_APP_PORT,
    GOOGLE_ID:process.env.REACT_APP_GOOGLE,
    GOOGLE_KEY:process.env.REACT_APP_GOOGLE_KEY,
  };
  