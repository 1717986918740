import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../../../assets/images/whiteLogo.svg";
import BtnMain from "../../../components/buttons/btn_main";
import { FormGroup, Dropdown } from "react-bootstrap";
import { useFormik } from "formik";
import profileImg from "./../../../assets/images/temp-img/profile-img.png";
import TextField from "../../../components/inputs/textfield";
import { remove_cookie } from "../../../utils";
function Header(props) {
  const [offset, setOffset] = useState(0);
  const formik = useFormik({
    initialValues: {
      selectProfession: "",
      registration: "",
    },
  });
  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  /******************* 
@Purpose : Used for Logout Handle
@Author : INIC
******************/
  const handleLogout = () => {
    localStorage.clear();
    remove_cookie("accessToken");
  };
  /******************* 
@Purpose : Used for render Dashboard Header UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <header
      className={`dashboard-header ${
        offset > 50 ? "header-shadow" : "no-shadow"
      }`}
    >
      <nav className="navbar navbar-expand navbar-light p-0">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="" className="d-inline-block align-text-top" />
        </Link>
        <div className={`container-fluid `}>
          <div className=" navbar-collapse inner-container justify-content-lg-between justify-content-end">
            <FormGroup className="form-group  input-ic-wrap m-0 d-lg-block d-none">
              <TextField
                type="text"
                placeholder="Search"
                name="Search"
                formik={formik}
                className="search-input-wrap"
                aria-label="Search"
                showUntoucherError
              />
              <i className="icon-search" aria-hidden="true"></i>
            </FormGroup>
            <div className="profile-img-wrap">
              <div className="profile-dropdown-wrap">
                <Dropdown>
                  <Dropdown.Toggle className="profile-img-block">
                    <div className="profile-img">
                      <img
                        src={profileImg}
                        alt=""
                        className="d-inline-block "
                      />
                    </div>
                    <span className="icon-angle-down"></span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/client-profile">
                      <span className="icon-user"></span> Profile
                    </Dropdown.Item>
                    <Dropdown.Item href="/change-password">
                      <span className="icon-lock-alt"></span> Change password
                    </Dropdown.Item>
                    <Dropdown.Item href="#/" onClick={handleLogout}>
                      <span className="icon-signout"></span> Sign out
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#/"
                      className="dropdown-item text-center justify-content-center"
                    >
                      <span className="icon-multiply"></span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <BtnMain
              className="ms-2 ms-sm-3  sidebar-toggler d-lg-none d-block"
              type="button"
              iconLeft={<span className="icon-bars"></span>}
              onClick={props.SidebarHandle}
            ></BtnMain>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
