import React from "react";
import { Row, Col } from "react-bootstrap";
import BtnMain from "../../components/buttons/btn_main";
import appointmentImg from "./../../assets/images/temp-img/dashboard-img.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import Layout from "../../components/Layout/dashboard-layout/Layout";
function DashBoard(_props) {
  var settings = {
    dots: false,
    infinite: false,
    variableWidth: true,
    speed: 500,
    slidesToShow: 1,
    prevArrow: false,
    nextArrow: false,
    slidesToScroll: 1,
  };
  const userInfo = useSelector((state) => state?.user?.userInfo);

  /******************* 
@Purpose : Used for render UI of Dashboard
@Parameter : {}
@Author : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content">
        <div className="big-heading h3">
          Hello {userInfo.firstName}! &#128075;
        </div>
        <div className="idea-box">
          <div className="idea-ic">
            <i className="icon-lightbulb-alt"></i>
          </div>
          <div className="idea-text">
            <div className="idea-heading b-700">Dashboard</div>
            <div className="idea-subheading">
              Your Dashboard will give you a snapshot of your payments,
              appointments and tasks that you need to complete.
            </div>
            <div className="idea-btn mt-3">
              <BtnMain
                text="Got it"
                className={"btn black-btn"}
                type="button"
              />
            </div>
          </div>
        </div>
        <div className="paymentStatus-wrap">
          <div className="label-heading xs-text b-700 mb-3">PAYMENTS</div>
          <div className="paymentStatus-block ">
            <Row>
              <Col xs={12} md={4}>
                <div className="paymentStatus-tile">
                  <div className="paymentStatus-innerTile">
                    <div className="paymentStatus-heading sm-text">
                      Outstanding
                    </div>
                    <div className="paymentStatus-amount h3 mb-0">
                      <span className="lg-text">￡</span>00.00
                    </div>
                  </div>
                  <div className="paymentStatus-footer sm-text">
                    <Link to={"/"} className="black-text">
                      View in Payments
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="paymentStatus-tile">
                  <div className="paymentStatus-innerTile">
                    <div className="paymentStatus-heading sm-text">Pending</div>
                    <div className="paymentStatus-amount h3 mb-0">
                      <span className="lg-text">￡</span>00.00
                    </div>
                  </div>
                  <div className="paymentStatus-footer sm-text">
                    <Link to={"/"} className="black-text">
                      View in Payments
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="paymentStatus-tile">
                  <div className="paymentStatus-innerTile">
                    <div className="paymentStatus-heading sm-text">Paid</div>
                    <div className="paymentStatus-amount h3 mb-0">
                      <span className="lg-text">￡</span>00.00
                    </div>
                  </div>
                  <div className="paymentStatus-footer sm-text">
                    <Link to={"/"} className="black-text">
                      View in Payments
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="pendingTask-wrap ">
          <div className="label-heading xs-text b-700 mb-3">
            {" "}
            TASKS TO COMPLETE
          </div>
          <Slider {...settings} className="pendingTask-block">
            <div className="pendingTask-tile black-text">
              <div className="pendingTask-inner-tile">
                <div className="lg-text b-700 mb-2 pendingTask-heading">
                  Task Request
                </div>
                <div className="sm-text mb-2 pendingTask-text">
                  Available Payment:{" "}
                  <span className="b-700 primary-text">￡30.00</span>
                </div>
                <div className="sm-text pendingTask-text">
                  Time left to complete:{" "}
                  <span className="b-700 primary-text">1h 23m 43s</span>
                </div>
              </div>
              <div className="pendingTask-foter">
                <div className="task-action">
                  <Link to="/" className="sm-text b-700 primary-text">
                    Accept
                  </Link>
                  <Link to="/" className="sm-text b-700 black-text">
                    Reject
                  </Link>
                </div>
                <Link to="/" className="sm-text grey-text clientCheck-details">
                  Check details <span className="icon-arrow-right"></span>
                </Link>
              </div>
            </div>
            <div className="pendingTask-tile black-text">
              <div className="pendingTask-inner-tile">
                <div className="lg-text b-700 mb-2 pendingTask-heading">
                  Task Request
                </div>
                <div className="sm-text mb-2 pendingTask-text">
                  Available Payment:{" "}
                  <span className="b-700 primary-text">￡30.00</span>
                </div>
                <div className="sm-text pendingTask-text">
                  Time left to complete:{" "}
                  <span className="b-700 primary-text">1h 23m 43s</span>
                </div>
              </div>
              <div className="pendingTask-foter">
                <div className="task-action">
                  <Link to="/" className="sm-text b-700 primary-text">
                    Accept
                  </Link>
                  <Link to="/" className="sm-text b-700 black-text">
                    Reject
                  </Link>
                </div>
                <Link to="/" className="sm-text grey-text clientCheck-details">
                  Check details <span className="icon-arrow-right"></span>
                </Link>
              </div>
            </div>
            <div className="pendingTask-tile black-text">
              <div className="pendingTask-inner-tile">
                <div className="lg-text b-700 mb-2 pendingTask-heading">
                  Task Request
                </div>
                <div className="sm-text mb-2 pendingTask-text">
                  Available Payment:{" "}
                  <span className="b-700 primary-text">￡30.00</span>
                </div>
                <div className="sm-text pendingTask-text">
                  Time left to complete:{" "}
                  <span className="b-700 primary-text">1h 23m 43s</span>
                </div>
              </div>
              <div className="pendingTask-foter">
                <div className="task-action">
                  <Link to="/" className="sm-text b-700 primary-text">
                    Accept
                  </Link>
                  <Link to="/" className="sm-text b-700 black-text">
                    Reject
                  </Link>
                </div>
                <Link to="/" className="sm-text grey-text clientCheck-details">
                  Check details <span className="icon-arrow-right"></span>
                </Link>
              </div>
            </div>
            <div className="pendingTask-tile black-text">
              <div className="pendingTask-inner-tile">
                <div className="lg-text b-700 mb-2 pendingTask-heading">
                  Task Request
                </div>
                <div className="sm-text mb-2 pendingTask-text">
                  Available Payment:{" "}
                  <span className="b-700 primary-text">￡30.00</span>
                </div>
                <div className="sm-text pendingTask-text">
                  Time left to complete:{" "}
                  <span className="b-700 primary-text">1h 23m 43s</span>
                </div>
              </div>
              <div className="pendingTask-foter">
                <div className="task-action">
                  <Link to="/" className="sm-text b-700 primary-text">
                    Accept
                  </Link>
                  <Link to="/" className="sm-text b-700 black-text">
                    Reject
                  </Link>
                </div>
                <Link to="/" className="sm-text grey-text clientCheck-details">
                  Check details <span className="icon-arrow-right"></span>
                </Link>
              </div>
            </div>
            <div className="pendingTask-tile black-text">
              <div className="pendingTask-inner-tile">
                <div className="lg-text b-700 mb-2 pendingTask-heading">
                  Task Request
                </div>
                <div className="sm-text mb-2 pendingTask-text">
                  Available Payment:{" "}
                  <span className="b-700 primary-text">￡30.00</span>
                </div>
                <div className="sm-text pendingTask-text">
                  Time left to complete:{" "}
                  <span className="b-700 primary-text">1h 23m 43s</span>
                </div>
              </div>
              <div className="pendingTask-foter">
                <div className="task-action">
                  <Link to="/" className="sm-text b-700 primary-text">
                    Accept
                  </Link>
                  <Link to="/" className="sm-text b-700 black-text">
                    Reject
                  </Link>
                </div>
                <Link to="/" className="sm-text grey-text clientCheck-details">
                  Check details <span className="icon-arrow-right"></span>
                </Link>
              </div>
            </div>
          </Slider>
        </div>
        <div className="appointment-wrap">
          <div className="label-heading xs-text b-700 mb-3">
            {" "}
            TODAYS APPOINTMENTS
          </div>
          <div className="appointment-block black-text">
            <div className="appointment-tile">
              <div className="user-block">
                <div className="userImg-block">
                  <div
                    className="user-img"
                    style={{ backgroundImage: "url(" + appointmentImg + ")" }}
                  ></div>
                </div>
                <div className="user-text">
                  <div className="sm-text black-text b-700">
                    Marco from RIghtangled
                  </div>
                  <div className="sm-text black-text ">
                    Professional Partner Manager
                  </div>
                </div>
              </div>
              <div className="appointment-date-wrap">
                <div className="appointment-time sm-text">9:00am - 9:30am</div>
                <div className="appointment-date sm-text">
                  Thur, Feb 22, 2021
                </div>
              </div>
              <div className="appointment-action d-flex">
                <BtnMain
                  text="Contact"
                  className="btn gray-line-btn me-4"
                  type="button"
                />
                <BtnMain
                  text="Zoom Link"
                  className="btn btn-primary"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default DashBoard;
